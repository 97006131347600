// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import defaultTo from 'lodash/defaultTo';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isArrayLike from 'lodash/isArrayLike';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import debugLogger from '../../lib/debug';
import * as log from '../../lib/debug.js';
import { getPath } from '../../lib/deep';
import { assignUniqueIds } from '../../lib/uniqueids';
import { DataSource, StaticDataSource } from '../../lib/datasource.js';

const debugLevel = log.LOG_LEVEL_DEBUG;
let debug = debugLogger('MetaBuilder', debugLevel);

export const XXSMALL = 100;
export const XSMALL = 200;
export const SMALL = 400;
export const MED = 600;
export const LARGE = 800;
export const XLARGE = 1000;
export const XXLARGE = 1200;

export const MODE_CREATE = 'create';
export const MODE_VIEW = 'view';
export const MODE_EDIT = 'edit';
export const MODE_LIST = 'list';

export class MetaBuilder {
  constructor(props) {
    if (!props) {
      props = {};
      debug.throw('props', props);
    }
    if (props.className === undefined) {
      debug.throw('className missing', props);
    }

    this.props = props;
    this.readOnly = props.readOnly;
    this.itemMap = new Map();
    this.items = [];
    this.defaults = [];
    this.inputs = [];
    this.sections = [];
    this.tabs = [];
    this.dataSources = [];
    if (isArray(props.dataSources)) this.dataSources = props.dataSources;
    this.query = [];

    this.targetSection = null;
    this.targetTab = null;
    this.className = props.className;

    this.onClickRow = undefined;

    // props default behavior, so if shared list and edit meta, these no ops vs undefined errors
    debug = debugLogger(`${props.className}::MetaBuilder`, debugLevel);
    this.view = castViewItf(props.view);
    this.parent = props.parentMeta;

    // deprecated
    this.parentView = castViewItf(props.parentView);
  }

  ReadOnly(isReadOnly = true) {
    this.readOnly = isReadOnly;
    return this;
  }

  applyReadOnly(v) {
    if (this.readOnly) {
      v.ReadOnly();
    }
    return v;
  }

  addSection(title = '', keyPath) {
    const s = new Section(this, title, this.sections.length, keyPath);
    this.sections.push(s);
    this.targetSection = s;
    if (!isNil(this.targetTab)) {
      this.targetTab.AddSection(s);
    }
    return this.applyReadOnly(s);
  }

  addTab(title, ds) {
    const t = new Tab(this, title, this.tabs.length + 1, ds);
    this.tabs.push(t);
    this.targetTab = t;
    return t;
  }

  newDataSource(url, name) {
    if (isNil(name)) {
      name = `${this.dataSources.length}`;
    }
    const ds = new DataSource(url, name);
    this.dataSources.push(ds);
    return ds;
  }

  newStaticDataSource(data, name) {
    if (isNil(name)) {
      name = `${this.dataSources.length}`;
    }
    if (!data) {
      data = {};
    }
    const ds = new StaticDataSource(data, name);
    this.dataSources.push(ds);
    return ds;
  }

  addDataSource(ds) {
    this.dataSources.push(ds);
    return ds;
  }

  addQuery(param) {
    this.query.push(param);
  }

  listenData(f) {
    debug.debug('listenData', this.dataSources);
    for (const ds of this.dataSources) {
      debug.debug('dataSources::ds.Listen()', ds, f);
      ds.Listen(f);
    }
    debug.debug('exit listenData');
  }

  fetchData() {
    // Ensure all datasources have data before ds.notifyListeners() notifies
    // This allows async fetches of all URLs for faster concurrent access, addHandlers
    // then syncronizes the notifyListeners()
    // across all datasources registered via MetaBuilder.newDataSource(), addDataSource(), ...
    //
    // This ensures MetaForm UI data sources all have data so data is present
    // when doing array maps/filters/etc for complex nested UI components
    // (e.g. dynamically populated drop downs nested inside table components, etc.)

    // This automatic syncronizaiton eliminates the need for
    // individiaul MetaForm views to:
    // 1)  syncronize multiple fetches
    //  e.g. via manually calling ds.SyncNotify()
    // or
    // 2) serialize the fetch order
    //   e.g. via ds1.Lazy() + ds2.OnLoad () => ds.1Fetch())
    for (const ds of this.dataSources) {
      for (const ds2 of this.dataSources) {
        if (ds === ds2) continue;
        ds.SyncNotify(ds2); // sync with all other data sources except self
      }
    }

    for (const ds of this.dataSources) {
      debug.debug('ds.Fetch', ds);
      if (!ds.isLazyLoad) {
        ds.Fetch();
      }
    }
    debug.log('exit fetchData');
  }

  // new style
  addField(keyPath, displayName) {
    return this.addColumn(keyPath, displayName);
  }

  stopPoll() {
    debug.log('stopFetchData', this.dataSources);
    for (const ds of this.dataSources) {
      ds.StopPoll();
    }
  }

  startPoll(pollMap) {
    for (const ds of this.dataSources) {
      if (pollMap[ds.name]) {
        ds.StartPoll();
      }
    }
  }

  addRowClick(onClickRow) {
    this.onClickRow = onClickRow;
    return this;
  }

  // oldStyle
  // addColumn adds a column to the table view that is NOT visbile by
  // default unless showDefault = true; will also display in the detail view
  addColumn(keyPath, displayName) {
    if (!keyPath) {
      debug.throw('missing keyPath');
    }
    if (displayName === undefined) {
      debug.throw('missing displayName');
    }
    if (this.itemMap.has(keyPath)) {
      debug.warn(`keyPath already exists: ${keyPath}`);
    }

    const c = new ColumnMeta(this, keyPath, displayName, this.items.length + 1);
    if (this.items.length === 0) {
      c.AutoFocus();
    }
    this.items.push(c);
    this.itemMap.set(keyPath, c);
    if (isNil(this.targetSection)) {
      this.targetSection = this.addSection('').NoChrome();
    }
    this.targetSection.AddField(c);
    return this.applyReadOnly(c);
  }

  // addColumn adds a column to the table view that is NOT visbile by
  // default unless showDefault = true; will also display in the detail view
  addInputTable(keyPath, displayName, showHeading) {
    if (this.itemMap.has(keyPath)) {
      debug.warn(`InputTable: keyPath already exists: ${keyPath}`);
    }

    const c = new InputTable(
      this,
      keyPath,
      displayName,
      this.items.length + 1,
      showHeading
    );

    this.items.push(c);
    this.itemMap.set(keyPath, c);
    if (!isNil(this.targetSection)) {
      this.targetSection.AddField(c);
    }
    return this.applyReadOnly(c);
  }

  cloneToStaticDataSources() {
    const result = [];
    if (!isEmpty(this.dataSources)) {
      for (const ds of this.dataSources) {
        result.push(ds.CloneStaticDataSource());
      }
    }

    return result;
  }

  getDataSource(name, throwErr = true) {
    debug.debug('getDataSource start', name, this.dataSources);

    if (isEmpty(this.dataSources)) {
      debug.warn('getDataSource no parent data sources', name);
      return null;
    }

    for (const ds of this.dataSources) {
      if (ds.name === name) {
        debug.debug('getDataSource found', name, ds);
        return ds;
      }
    }

    if (throwErr) {
      debug.throw('getDataSource not found', name);
    }

    debug.error('getDataSource not found', name);
    return null;
  }

  // f(form) => (form with defaults)
  formDefaults(f) {
    this.fFormDefaults = f;
    return this;
  }

  // takes current form in, and returns form with defaults
  getFormDefaults(form) {
    if (isFunction(this.fFormDefaults)) {
      const res = this.fFormDefaults(form);
      return res || form; // metaforms can optionally return a new form, or modify the current one
    }
    return null;
  }

  // returns a new namespaced meta builder for scoping a metaform to a different sub namespace of this metabuilder
  // for example, rack templates has a top level metabuilder that can be reused in a different sub namespace on the
  // rack view 'form.config...'
  Namespace(namespace) {
    return new NamespacedMetaBuilder(this, namespace);
  }
}

export class NamespacedMetaBuilder extends MetaBuilder {
  constructor(mb, namespace) {
    super(mb.props);
    Object.assign(this, mb); // share arrays with parent MB
    this.namespace = namespace;
    this.view = castViewItf(this.props.view, this.namespace);
  }

  qualPath(keyPath) {
    return `${this.namespace}.${keyPath}`;
  }

  addSection(title, keyPath) {
    keyPath = this.qualPath(keyPath);
    return super.addSection(title, keyPath);
  }

  addField(keyPath, displayName, withChildren) {
    keyPath = this.qualPath(keyPath);
    return super.addColumn(keyPath, displayName);
  }

  addColumn(keyPath, displayName) {
    keyPath = this.qualPath(keyPath);
    return super.addColumn(keyPath, displayName);
  }

  addInputTable(keyPath, displayName) {
    keyPath = this.qualPath(keyPath);
    return super.addInputTable(keyPath, displayName);
  }

  newDataSource(url, name) {
    return super.newDataSource(url, name);
  }

  newStaticDataSource(data, name) {
    return super.newStaticDataSource(data, name);
  }

  addDataSource(ds) {
    return super.addDataSource(ds);
  }
}

// cast the MetaForm or ListViewContainer to a defined view interface,
// and return a separate interface object to ensure no leakage
function castViewItf(view = {}, namespace) {
  const itf = {};
  if (!view.initForm) {
    itf.initForm = () => {
      debug.warn('initForm() noop');
    };
  } else if (isEmpty(namespace)) {
    itf.initForm = view.initForm;
  } else {
    itf.initForm = (v) => {
      view.initForm(v, namespace);
    };
  }

  if (!view.setFormValue) {
    itf.setFormValue = () => {
      debug.warn('setFormValue() noop');
    };
  } else if (isEmpty(namespace)) {
    itf.setFormValue = view.setFormValue;
  } else {
    itf.setFormValue = (k, v) => {
      view.setFormValue(`${namespace}.${k}`, v);
    };
  }

  if (!view.addFormValue) {
    itf.addFormValue = () => {
      debug.warn('addFormValue() noop');
    };
  } else if (isEmpty(namespace)) {
    itf.addFormValue = view.addFormValue;
  } else {
    itf.addFormValue = (k, v) => {
      view.addFormValue(`${namespace}.${k}`, v);
    };
  }

  if (!view.removeFormValue) {
    itf.removeFormValue = () => {
      debug.warn('removeFormValue() noop');
    };
  } else if (isEmpty(namespace)) {
    itf.removeFormValue = view.removeFormValue;
  } else {
    itf.removeFormValue = (k, p) => {
      view.removeFormValue(`${namespace}.${k}`, p);
    };
  }

  if (!view.updateFormValue) {
    itf.updateFormValue = () => {
      debug.warn('updateFormValue() noop');
    };
  } else if (isEmpty(namespace)) {
    itf.updateFormValue = view.updateFormValue;
  } else {
    itf.updateFormValue = (k, v, p, r) => {
      view.updateFormValue(`${namespace}.${k}`, v, p, r);
    };
  }

  if (!view.state) {
    itf.formDefaultTo = (ks) => {
      debug.warn(`formDefaultTo() NOOP: ${ks}`);
    };
  } else {
    itf.formDefaultTo = (ks, def) => {
      if (isEmpty(namespace)) {
        return defaultTo(getPath(view.state, `form.${ks}`), def);
      }
      return defaultTo(getPath(view.state, `form.${namespace}.${ks}`), def);
    };
  }

  if (!view.state) {
    itf.getForm = () => {
      debug.warn('getForm() NOOP: ');
    };
  } else {
    itf.getForm = () => {
      if (isEmpty(namespace)) {
        return getPath(view.state, 'form');
      }
      return getPath(view.state, `form.${namespace}`);
    };
  }

  if (!view.addAlert) {
    itf.addAlert = () => {
      debug.warn('props() noop');
    };
  } else {
    itf.addAlert = view.addAlert;
  }

  // this following should only be used for exceptional cases where formDefaultTo() cannot be used
  // due to issues reusing meta forms in different contexts e.g. (rack template forms being resued for rack forms via a nested config attribute)
  if (!view.state) {
    itf.stateDefaultTo = (ks) => {
      debug.warn(`stateDefaultTo() NOOP: ${ks}`);
    };
  } else {
    itf.stateDefaultTo = (ks, def) => {
      const val = getPath(view.state, ks);
      if (val === undefined) {
        return def;
      }
      return val;
    };
  }

  if (!view.props) {
    itf.propDefaultTo = (ks) => {
      debug.warn(`props() NOOP: ${ks}`);
    };
  } else {
    itf.propDefaultTo = (ks, def) => {
      const val = getPath(view.props, ks);
      if (val === undefined) {
        return def;
      }
      return val;
    };
  }

  if (!view.state) {
    itf.state = (ks, throwErr = true) => {
      if (throwErr) {
        const msg = `Required view.state "${ks}" missing. NOOP HANDLER!`;
        debug.error(msg);
        throw new Error(`MetaBuilder:${msg}`);
      } else {
        debug.warn(`state() NOOP: ${ks}`);
      }
    };
  } else {
    itf.state = (ks, throwErr = true) => {
      const val = getPath(view.state, ks);
      if (throwErr) {
        if (val === undefined) {
          const msg = `Required view.state "${ks}" missing.`;
          debug.error(`${msg}, found state:`, view.state);
          throw new Error(`MetaBuilder:${msg}`);
        }
      }
      return val;
    };
  }

  if (!view.props) {
    itf.prop = (ks, throwErr = true) => {
      if (throwErr) {
        const msg = `Required view.props "${ks}" missing. NOOP HANDLER!`;
        debug.error(msg);
        throw new Error(`MetaBuilder:${msg}`);
      } else {
        debug.warn(`props() NOOP: ${ks}`);
      }
    };
  } else {
    itf.prop = (ks, throwErr = true) => {
      const val = getPath(view.props, ks);
      if (throwErr) {
        if (val === undefined) {
          const msg = `Required view.props "${ks}" missing.`;
          debug.error(`${msg}, found props:`, view.props);
          throw new Error(`MetaBuilder:${msg}`);
        }
      }
      return val;
    };
  }

  return itf;
}

// notifyHandlers helper
function notifyHandlers(name, handlers, args, keyPath) {
  let result;
  if (isEmpty(handlers)) {
    debug.log(`no handlers for "${name}"`, args);
    return;
  }
  for (const f of handlers) {
    try {
      debug.log(`-${name}`, keyPath, f, args);
      if (f(...args) === false) {
        // if a handler actually returns false rather
        // than undefined we'll return false. This lets
        // a handler have an opinion whereas anything else
        // is don't care
        result = false;
      }
    } catch (err) {
      const msg = `${name}(${keyPath}):${err}`;
      debug.throw(msg, err, f);
    }
    return result;
  }
}

export const SectionTypeFields = 'fields';
export const SectionTypeTable = 'table';
export const SectionTypeDefault = 'default';
export const SectionTypeCustom = 'custom';

class Section {
  constructor(mb, title, id, keyPath) {
    this.mb = mb;
    this.keyPath = defaultTo(keyPath, '');
    this.title = title;
    this.id = id;
    this.fields = [];
    this.editHandlers = [];
    this.tableClass = null;
    this.type = SectionTypeDefault;
    this.expanded = false;
    this.dataSource = null;
    this.noChrome = false;
    this.grid = false;
    this.overviewText = '';
    this.footerNote = '';
    this.elevation = '';
    this.background = '';
  }

  AddField(field) {
    if (this.type !== SectionTypeFields && this.type !== SectionTypeDefault) {
      throw new Error(`section is already a different type: ${this.type}`);
    }
    this.type = SectionTypeFields;
    this.fields.push(field);
    return this;
  }

  OverviewText(msg) {
    this.overviewText = msg;
    return this;
  }

  Background(background) {
    this.background = background ? background : 'background-back';
    this.elevation = 'small';
    return this;
  }

  FooterNote(msg) {
    this.footerNote = msg;
    return this;
  }

  // inline smart ListViewContainer that operates independently from rest of the form (e.g. team members, etc.)
  // meaning that it's loaded from REST, and operations are immediately written via REST
  // See InputTable for an inline, in memory editable table that can be used recursively for multi-level edits
  Table(listItemTable) {
    if (this.type !== SectionTypeFields && this.type !== SectionTypeDefault) {
      throw new Error(`section is already a different type: ${this.type}`);
    }
    this.type = SectionTypeTable;
    this.tableClass = listItemTable;
    return this;
  }

  Expanded() {
    this.expanded = true;
    return this;
  }

  NoChrome(grid = true) {
    this.noChrome = true;
    this.grid = grid;

    return this;
  }

  Width(px) {
    this.width = px;
    return this;
  }

  MinWidth(px) {
    this.minWidth = px;
    return this;
  }

  MaxWidth(px) {
    this.maxWidth = px;
    return this;
  }

  ReadOnly(isReadOnly = true) {
    this.readOnly = isReadOnly;
    return this;
  }

  IsReadOnly() {
    return this.readOnly === true;
  }

  // f returns true if visible, false if not
  // if Visible() is not called, all fields default to visible = true
  Visible(f) {
    this.fVisible = f;
    return this;
  }

  IsVisible() {
    if (!isFunction(this.fVisible)) {
      return true;
    }
    return this.fVisible();
  }

  // edit handlers
  EditDialogMeta(f, size = 'medium') {
    this.editDialogMeta = f;
    this.editDialogSize = size;
    return this;
  }

  // f returns
  OnInitEditForm(f) {
    this.fInitEditForm = f;
  }

  GetInitEditForm() {
    let result = {};
    if (isFunction(this.fInitEditForm)) {
      result = this.fInitEditForm();
    } else if (!isEmpty(this.keyPath)) {
      const keyPathForm = this.mb.view.state(`form.${this.keyPath}`, false);
      if (isObject(keyPathForm)) {
        result = keyPathForm;
      }
    }
    return result;
  }

  OnEdit(f) {
    this.editHandlers.push(f);
    return this;
  }

  FireOnEdit(data) {
    return notifyHandlers('OnEdit', this.editHandlers, arguments);
  }

  IsEditEnabled() {
    if (!isFunction(this.fEditable)) {
      return !isEmpty(this.editHandlers);
    }
    return this.fEditable();
  }

  // f returns true if editable, false if not
  // if Editable() is not called, edit handlers will determine if
  // edit is visible.
  Editable(f) {
    this.fEditable = f;
    return this;
  }
}

class Tab {
  constructor(mb, title, id, ds) {
    this.mb = ds || mb;
    this.title = title;
    this.id = id;
    this.sections = [];
  }

  AddSection(s) {
    this.sections.push(s);
    return this;
  }

  // f returns true if visible, false if not
  // if Visible() is not called, all fields default to visible = true
  Visible(f) {
    this.fVisible = f;
    return this;
  }

  IsVisible() {
    if (!isFunction(this.fVisible)) {
      return true;
    }
    return this.fVisible();
  }
}

export const FieldTypeCustom = 'custom';
export const FieldTypeText = 'text';
export const FieldTypeTextArea = 'textarea';
export const FieldTypeDropDown = 'dropdown';
export const FieldTypeCountryDropDown = 'countryDropdown';
export const FieldTypeRadioGroup = 'radiogroup';
export const FieldTypeMultiSelect = 'multiselect';
export const FieldTypeCheckBox = 'checkbox';
export const FieldTypeLabel = 'label';
export const FieldTypeHidden = 'hidden';
export const FieldTypeInputTable = 'inputtable';
export const FieldTypePassword = 'password';

// todo refactor above with this column class
class ColumnMeta {
  constructor(mb, keyPath, displayName, order) {
    this.mb = mb;
    this.makeLink = false;
    this.makeLinkBaseUrl = null;
    this.makeLinkExt = '';
    this.columnName = keyPath;
    this.keyPath = keyPath;
    this.type = FieldTypeText;
    this.order = order;
    this.displayName = displayName;
    this.visible = true;
    this.isDefault = false;
    this.isInput = false;
    this.isInteger = false;
    this.isRequired = false;
    this.maxLength = 256;
    this.minLength = 0;
    this.regEx = null;
    this.textArea = false;
    this.initHandlers = []; // init callback funcs
    this.changeHandlers = []; // change callback funcs
    this.cellInitHandlers = []; // init cell callback funcs for InputTable cells
    this.cellChangeHandlers = []; // change cell callback funcs InputTable cells
    this.customValidators = []; // custom validators
    this.autoFocus = false;
    this.isReadOnly = false;
    this.dataSource = null;
    this.xform = null;
    this.fieldXform = null;
    this.modXform = null;
    this.minWidth = SMALL;
    this.searchable = true;
    this.mask = null;
    this.info = '';
    this.inputHelp = '';
    this.texts = '';
    this.optioinal = false;
    this.textAlign = 'left';
  }

  Custom(component, metadata) {
    this.type = FieldTypeCustom;
    this.customComponent = component;
    this.customComponentMetadata = metadata;
    return this;
  }

  // show by default in main listview table
  Default() {
    if (!this.isDefault) {
      this.isDefault = true;
      this.mb.defaults.push(this.columnName);
      return this;
    }
  }

  // make into a link when text-field is displayed as read-only
  MakeLink(url, ext) {
    // TODO: verify that this.type == FieldTypeText
    this.makeLink = true;
    this.makeLinkBaseUrl = url;
    this.makeLinkExt = ext;
    return this;
  }

  // show as input in create/edit views
  Input(value = null) {
    this.isInput = true;
    this.type = FieldTypeText;
    this.mb.inputs.push(this.columnName);
    if (!isNil(value)) {
      this.inputDefaultValue = value;
    }
    return this;
  }

  Help(help = '') {
    this.inputHelp = help;

    return this;
  }

  Mask(maskObject) {
    this.mask = maskObject;
    return this;
  }

  PrefixKeyPath(keyPath) {
    this.prefixKeyPath = keyPath;
    return this;
  }

  Hidden() {
    this.type = FieldTypeHidden;
    return this;
  }

  AutoFocus() {
    // auto focus is automatically applied to the first field
    // but we allow this to be overridden in the meta view
    // and set the autoFocus for the first field to false here
    // if it's been set already
    if (this.mb.items.length > 0) {
      this.mb.items[0].autoFocus = false;
    }
    this.autoFocus = true;
    return this;
  }

  ReadOnly(isReadOnly = true) {
    this.isReadOnly = isReadOnly;
    return this;
  }

  // f returns true if visible, false if not
  // if Visible() is not called, all fields default to visible = true
  Visible(f) {
    this.fVisible = f;
    return this;
  }

  Number(min, max, customErrMsg) {
    this.isNumber = true;
    this.numMin = min;
    this.numMax = max;
    this.numMinMaxError = customErrMsg;
    return this;
  }

  // DataXform used to bind a datasource to this field e.g. DropDown, InputTable,
  // and others that are populated from explicit data sources
  DataXform(dataSource, xform) {
    this.dataSource = dataSource;
    this.xform = xform;

    // if this ColumnMeta is in an InputTable, then don't register the datasource xform
    if (this.mb instanceof InputTable) return this;

    dataSource.Xform(this.keyPath, xform);

    return this;
  }

  // init handler: f(json)
  OnInit(f) {
    this.initHandlers.push(f);
    return this;
  }

  FireOnInit(json) {
    notifyHandlers('OnInit', this.initHandlers, arguments);
  }

  // change handler: f(value)
  OnChange(f) {
    this.changeHandlers.push(f);
    return this;
  }

  FireOnChange(value, oldVal) {
    notifyHandlers('OnChange', this.changeHandlers, arguments);
  }

  ViewModalItemLink() {
    this.viewModalItemLink = true;
    return this;
  }

  TextArea() {
    this.type = FieldTypeTextArea;
    this.textArea = true;
    return this;
  }

  // DropDown defines a dropdown field populated from DataXform(), e.g.
  // DataXform( ptData, (json) => json.map( (t) => ({ id: t.id, name: t.name })))
  DropDown(placeholder, placeholderIfEmpty, optional) {
    this.type = FieldTypeDropDown;
    this.placeholder = defaultTo(placeholder, 'Select an item ...');
    this.placeholderIfEmpty = defaultTo(placeholderIfEmpty, 'No items found.');
    this.optional = optional || false;

    return this;
  }

  CountryDropDown(placeholder, placeholderIfEmpty, optional) {
    this.type = FieldTypeCountryDropDown;
    this.placeholder = defaultTo(placeholder, 'Select an item ...');
    this.placeholderIfEmpty = defaultTo(placeholderIfEmpty, 'No items found.');
    this.optional = optional || false;

    return this;
  }

  RadioGroup() {
    this.type = FieldTypeRadioGroup;
    return this;
  }

  // layout direction - "row" or "column"
  Direction(dir) {
    this.direction = dir;
    return this;
  }

  // MultiSelect defines a field populated from DataXform(), e.g.
  // DataXform( ptData, (json) => json.map( (t) => ({ id: t.id, name: t.name })))
  MultiSelect(placeholder, placeholderIfEmpty) {
    this.type = FieldTypeMultiSelect;
    this.placeholder = defaultTo(placeholder, 'Select one or more items ...');
    this.placeholderIfEmpty = defaultTo(placeholderIfEmpty, 'No items found.');
    return this;
  }

  AllowCreate() {
    this.allowCreate = true;
    return this;
  }

  NotSearchable() {
    this.searchable = false;
    return this;
  }

  CheckBox(defaultValue, toggle) {
    this.type = FieldTypeCheckBox;
    if (!isNil(defaultValue)) {
      this.inputDefaultValue = defaultValue;
      this.toggle = toggle;
    }
    return this;
  }

  Password() {
    this.type = FieldTypePassword;
    return this;
  }

  Label() {
    this.type = FieldTypeLabel;
    return this;
  }

  // used with MultiSelect() to close select dropdown after first selection
  // which makes it more like a dropdown, with the ability to select multiple later
  UsuallySingleSelect() {
    this.usuallySingleSelect = true;
    return this;
  }

  Clearable() {
    this.isClearable = true;
    return this;
  }

  ShowLink() {
    this.showLink = true;
    return this;
  }

  Width(px) {
    this.width = px;
    return this;
  }

  MinWidth(px) {
    this.minWidth = px;
    return this;
  }

  MinCellWidth(px) {
    this.minCellWidth = px;
    return this;
  }

  MaxWidth(px) {
    this.maxWidth = px;
    return this;
  }

  SmallSize() {
    this.smallSize = true;
    return this;
  }

  LargeSize() {
    this.largeSize = true;
    return this;
  }

  Required(hint) {
    this.isRequired = true;
    this.isRequiredHint = hint;
    return this;
  }

  RequiredSometimes(fRequired, hint) {
    this.fRequired = fRequired;
    this.isRequiredHint = hint;
    return this;
  }

  MaxLength(n) {
    this.maxLength = n;
    return this;
  }

  MinLength(n) {
    this.minLength = n;
    return this;
  }

  RegEx(regEx, errorMsg) {
    this.regEx = regEx;
    this.regExErrorMsg = errorMsg;
    return this;
  }

  MustExistIn(map, errMsg) {
    this.existsMap = map;
    this.existsErrMsg = errMsg;
    return this;
  }

  UniqueIn(array) {
    this.uniqueArray = array;
    return this;
  }

  MustMatch(field) {
    this.mustMatch = field;
    field.mustMatch = this;
    return this;
  }

  TextAlign(align) {
    this.textAlign = align;
    return this;
  }

  CustomValidator(f) {
    this.customValidators.push(f);
    return this;
  }

  IsValid(value) {
    if (this.mb.readOnly) {
      return new ValidationResult(true, '');
    }
    if (this.isRequired || (isFunction(this.fRequired) && this.fRequired())) {
      if (isNil(value) || (isArrayLike(value) && isEmpty(value))) {
        if (this.isRequiredHint) {
          return new ValidationResult(
            false,
            `${this.displayName} is a required field. ${this.isRequiredHint}`,
          );
        }
        return new ValidationResult(
          false,
          `${this.displayName} is a required field.`,
        );
      }
    }

    if (this.minLength > 0) {
      if (value.length < this.minLength) {
        return new ValidationResult(
          false,
          `${this.displayName} has a minimum length of ${this.minLength}.`,
        );
      }
    }

    if (
      !isNil(value) &&
      this.isNumber &&
      this.numMin !== undefined &&
      this.numMax !== undefined
    ) {
      debug.debug(
        `IsValid: ${this.keyPath} number: ${this.numMin}-${
          this.numMax
        }; actual: ${value}(${typeof value})`,
      );
      if (!isNumber(value) || value < this.numMin || value > this.numMax) {
        if (this.numMinMaxError === undefined) {
          return new ValidationResult(
            false,
            `${this.displayName} must be a number in the range: ${this.numMin}-${this.numMax}.`,
          );
        }
        return new ValidationResult(false, this.numMinMaxError);
      }
    }

    if (!isNil(this.uniqueArray)) {
      debug.log('unique constraint: ', this.uniqueArray, [this.keyPath, value]);
      if (
        value !== '' &&
        findIndex(this.uniqueArray, [this.keyPath, value]) >= 0
      ) {
        return new ValidationResult(
          false,
          `${this.displayName} already exists, please choose a different value.`,
        );
      }
    }

    if (!isNil(this.existsMap) && !isEmpty(value)) {
      debug.log('exists constraint: ', this.existsMap, [this.keyPath, value]);
      if (isArray(value)) {
        const invalid = [];
        for (const v of value) {
          if (!this.existsMap.has(v)) {
            invalid.push(v);
          }
        }
        if (!isEmpty(invalid)) {
          return new ValidationResult(
            false,
            `${this.displayName} (${invalid.join(', ')}) ${this.existsErrMsg}`,
          );
        }
      } else if (!this.existsMap.has(value)) {
        return new ValidationResult(
          false,
          `${this.displayName} (${value}) ${this.existsErrMsg}`,
        );
      }
    }

    if (!isNil(this.mustMatch)) {
      debug.log('must match:', this.mustMatch, [this.keyPath]);
      const val = this.mb.view.state(`form.${this.keyPath}`, false);
      const val2 = this.mb.view.state(`form.${this.mustMatch.keyPath}`, false);
      if (val !== val2) {
        return new ValidationResult(
          false,
          `${this.displayName} must match ${this.mustMatch.displayName}.`,
        );
      }
    }

    for (const customValidator of this.customValidators) {
      const vr = customValidator(value);
      if (!vr?.ok) return vr;
    }

    if (isEmpty(value)) {
      // skip regex validation if not Required() and value is empty
      return new ValidationResult(true, '');
    }

    if (!isNil(value) && !isNil(this.regEx)) {
      if (isArray(value)) {
        const invalid = [];
        for (const v of value) {
          if (!isString(v) || !v.match(this.regEx)) {
            invalid.push(v);
          }
        }
        if (!isEmpty(invalid)) {
          if (this.regExErrorMsg === undefined) {
            return new ValidationResult(
              false,
              `(${invalid.join(', ')}) must match the pattern: ${this.regEx}`,
            );
          }
          return new ValidationResult(
            false,
            `(${invalid.join(', ')}) ${this.regExErrorMsg}`,
          );
        }
      } else if (!isString(value) || !value.match(this.regEx)) {
        if (this.regExErrorMsg === undefined) {
          return new ValidationResult(
            false,
            `${this.displayName} must match the pattern: ${this.regEx}`,
          );
        }
        return new ValidationResult(false, this.regExErrorMsg);
      }
    }

    return new ValidationResult(true, '');
  }

  // When ColumnMeta fields are used in an InputTable they have special init and change callbacks
  // CellXform is used to provide the xform used for an InputTable cell
  // where the InputTable already has a datasource and the individual cells
  // are passed the rowData to xform for each cell. This may be used to
  // simply extract the cell value, or extract an array of id/value pairs for
  // a dropdown list.
  CellXform(xform) {
    this.xform = xform;
    return this;
  }

  ModXform(xform) {
    this.modXform = xform;
    return this;
  }

  FieldXform(xform) {
    this.fieldXform = xform;
    return this;
  }

  // cell init handler for InputTable: f(row, rowData, tcol)
  OnCellInit(f) {
    this.cellInitHandlers.push(f);
    return this;
  }

  FireOnCellInit(row, rowData, tcol) {
    notifyHandlers('OnCellInit', this.cellInitHandlers, arguments);
  }

  // cell change handler for InputTable: f(row, rowData, value)
  OnCellChange(f) {
    this.cellChangeHandlers.push(f);
    return this;
  }

  FireOnCellChange(row, rowData, value, oldValue) {
    notifyHandlers('OnCellChange', this.cellChangeHandlers, arguments);
  }

  Info(str) {
    this.info = str;
    return this;
  }
}

// InputTable is used for create/edit/view of an arrays of objects in memory
// vs. the nested Section tables which are inline ListItemContainers.
// InputTable is both a ColumnMeta that renders as an editable inline table
// with columns defined using an extended MetaBuilder syntax
// that allows addColumn() just like we do for the ListView table
// but also OnInit(), OnChange(), OnAdd(), OnRemove(), events that allow
// setting the form state in the generic view containers, such that
// it can be PUT/POST with a single REST call.
// InputTable can also provide the columns for a modal edit form
// such that add/edit of an item in an InputTable can be done with a Model edit
// form using the same addColumn() syntax we use for ListView item views
// InputTable and may be used recursively to create/edit/view arrays of objects,with arrays of objects, ...
class InputTable extends MetaBuilder {
  constructor(mb, keyPath, displayName, order, showHeading) {
    const props = { className: `${mb.className}::InputTable` };
    super(props);
    /*
    //MetaBuilder constructor
    this.itemMap = new Map()
    this.items = []
    this.defaults = []
    this.inputs = []
    this.sections = []
    this.tabs = []
    this.dataSources = []
    this.targetSection = null
    this.targetTab = null
     */

    // polymorphic with ColumnMeta for GUI views
    this.mb = mb; // parent mb since InputTable is treated as a item in the parent mb
    this.columnName = keyPath;
    this.keyPath = keyPath;
    this.type = FieldTypeInputTable;
    this.order = order;
    this.displayName = displayName;
    this.showHeading = !!showHeading;
    this.visible = true;
    this.isInput = true;
    this.initHandlers = []; // init callback funcs
    this.changeHandlers = []; // change callback funcs
    this.customValidators = [];
    this.isReadOnly = false; // determines if fields are editable after initial create
    this.dataSource = null;
    this.xform = null;
    this.minWidth = LARGE;
    this.helperText = '';

    // InputTable specific handlers
    this.addHandlers = []; // add callback
    this.deleteHandlers = []; // delete callback
    this.resetHandlers = []; // reset callback
    this.editHandlers = []; // edit callback
    this.upDownHandlers = []; // up/down button callback
    this.onClickRow = undefined;

    this.addRowClick = (onRowClick) => {
      this.onClickRow = onRowClick;
    };

    // cellInitHandler is added to FireOnInit() as the last handler
    this.cellInitHandler = (json) => {
      if (isNil(json.data)) {
        debug.log('TABLE::OnInit, json data missing', json);
        return;
      }

      const { rawrows } = json.data;
      // initilaize each table cell
      for (let i = 0; i < rawrows.length; i++) {
        const rawrow = rawrows[i];
        for (const tcol of this.items) {
          tcol.FireOnCellInit(i, rawrow, tcol);
        }
      }
    };

    // cellChangeHandler is added to FireOnChange() as the last handler
    this.cellChangeHandler = (subKeyPath, row, rawRowData, newVal, oldVal) => {
      debug.debug(
        'TABLE: change notification',
        subKeyPath,
        row,
        rawRowData,
        newVal,
        oldVal,
      );
      const tcol = this.itemMap.get(subKeyPath);
      if (tcol) {
        tcol.FireOnCellChange(row, rawRowData, newVal, oldVal);
      }
    };
  }

  Helper(helperText) {
    this.helperText = helperText;

    return this;
  }

  // rowsXform provides the input array for the table
  DataXform(dataSource, rowsXform) {
    this.dataSource = dataSource;
    this.xform = (json) => {
      // get the datasource array to populate this table with before we transform the data
      // for specific cells
      const rawrows = rowsXform(json) || [];
      const data = {
        cols: this.items,
        rows: [],
        rawrows,
      };

      for (let i = 0; i < rawrows.length; i++) {
        const xformrow = {};
        for (const tcol of this.items) {
          if (isFunction(tcol.xform)) {
            if (tcol.dataSource) {
              xformrow[tcol.keyPath] = tcol.xform(tcol.dataSource.Data(), i);
            } else {
              xformrow[tcol.keyPath] = tcol.xform(rawrows[i], i);
            }
          }
        }
        data.rows.push(xformrow);
      }
      return data;
    };
    // register the InputTable transform with the datasource
    this.dataSource.Xform(this.keyPath, this.xform);

    return this;
  }

  ReadOnly(isReadOnly = true) {
    this.isReadOnly = isReadOnly;
    return this;
  }

  // init handler
  OnInit(f) {
    this.initHandlers.push(f);
    return this;
  }

  FireOnInit(json) {
    notifyHandlers(
      'OnInit',
      [...this.initHandlers, this.cellInitHandler],
      arguments,
    );
  }

  // change handler
  OnChange(f) {
    this.changeHandlers.push(f);
    return this;
  }

  FireOnChange(subKeyPath, row, rawRowData, value, oldVal) {
    notifyHandlers(
      'OnChange',
      [...this.changeHandlers, this.cellChangeHandler],
      arguments,
    );
  }

  // set MetaBuilder for add item dialog
  AddDialogMeta(f, size = 'medium') {
    this.addDialogMeta = f;
    this.addDialogSize = size;
  }

  // add handlers
  OnAdd(f) {
    this.addHandlers.push(f);
    return this;
  }

  IsAddEnabled() {
    return !isEmpty(this.addHandlers);
  }

  FireOnAdd(rowData, modal) {
    // ensures all new objects have __uniqueid set
    // works in conjunction with datasource SetData() and Fetch() which
    // also assign __uniqueid
    assignUniqueIds(rowData);
    return notifyHandlers('OnAdd', this.addHandlers, arguments);
  }

  OnUpDown(f) {
    this.upDownHandlers.push(f);
  }

  FireOnUpDown(rowData) {
    notifyHandlers('OnUpDown', this.upDownHandlers, arguments);
  }

  IsUpDownEnabled() {
    return !isEmpty(this.upDownHandlers);
  }

  // delete handlers
  OnDelete(f) {
    this.deleteHandlers.push(f);
    return this;
  }

  FireOnDelete(rowData) {
    notifyHandlers('OnDelete', this.deleteHandlers, arguments);
  }

  IsDeleteEnabled(rowData) {
    if (!isFunction(this.fDeletable)) {
      return !isEmpty(this.deleteHandlers);
    }
    return this.fDeletable(rowData);
  }

  Deletable(f) {
    this.fDeletable = f;
    return this;
  }

  // reset handlers
  OnReset(f) {
    this.resetHandlers.push(f);
    return this;
  }

  FireOnReset(rowData) {
    notifyHandlers('OnReset', this.resetHandlers, arguments);
  }

  IsResetEnabled() {
    if (!isFunction(this.fResetable)) {
      return !isEmpty(this.resetHandlers);
    }
    return this.fResetable();
  }

  Resetable(f) {
    this.fResetable = f;
    return this;
  }

  // edit handlers
  EditDialogMeta(f, size = 'medium') {
    this.editDialogMeta = f;
    this.editDialogSize = size;
    return this;
  }

  OnEdit(f) {
    this.editHandlers.push(f);
    return this;
  }

  FireOnEdit(rowData, modal) {
    return notifyHandlers('OnEdit', this.editHandlers, arguments);
  }

  IsEditEnabled(rowData) {
    if (!isFunction(this.fEditable)) {
      return !isEmpty(this.editHandlers);
    }
    return this.fEditable(rowData);
  }

  // f returns true if editable, false if not
  // if Editable() is not called, edit handlers will determine if
  // edit is visible.
  Editable(f) {
    this.fEditable = f;
    return this;
  }

  // set MetaBuilder for add item dialog
  ViewDialogMeta(f) {
    this.viewDialogMeta = f;
  }

  Required(hint) {
    this.isRequired = true;
    this.isRequiredHint = hint;
    return this;
  }

  RequiredSometimes(fRequired, hint) {
    this.fRequired = fRequired;
    this.isRequiredHint = hint;
    return this;
  }

  MinLength(n) {
    this.minLength = n;
    return this;
  }

  CustomValidator(f) {
    this.customValidators.push(f);
    return this;
  }

  IsValid(value) {
    if (this.mb.readOnly) {
      return new ValidationResult(true, '');
    }
    if (this.isRequired || (isFunction(this.fRequired) && this.fRequired())) {
      if (isEmpty(value)) {
        if (this.isRequiredHint) {
          return new ValidationResult(
            false,
            `${this.displayName} are required. ${this.isRequiredHint}`,
          );
        }
        return new ValidationResult(false, `${this.displayName} are required.`);
      }
    }

    if (this.minLength > 0) {
      if (isEmpty(value) || value.length < this.minLength) {
        return new ValidationResult(
          false,
          `${this.displayName} requires at least ${this.minLength} items.`,
        );
      }
    }

    for (const customValidator of this.customValidators) {
      const vr = customValidator(value);
      if (!vr.ok) return vr;
    }

    if (!isEmpty(value) && isArray(value)) {
      const cellResults = [];
      for (const tcol of this.items) {
        for (const rowdata of value) {
          const cellData = get(rowdata, tcol.keyPath);
          const cellResult = tcol.IsValid(cellData);
          if (!cellResult.ok) {
            cellResults.push(cellResult);
          }
        }
      }
      if (!isEmpty(cellResults)) {
        return new ValidationResult(false, '', cellResults);
      }
    }

    return new ValidationResult(true, '');
  }
}

export class ValidationResult {
  constructor(ok, msg, cellResults = []) {
    this.ok = ok;
    this.msg = msg;
    this.cellResults = cellResults;
    if (ok) {
      this.state = 'success';
    } else {
      this.state = 'error';
    }
  }
}

export default MetaBuilder;
