// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import defaultTo from 'lodash/defaultTo';
import differenceWith from 'lodash/differenceWith';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import {
  URL_VOLUME_FLAVOR as BASE_URL,
  URL_PROJECTS,
  URL_VOLUME_FLAVOR_CLASSIFIER_INFO,
} from '../../routes/consts';
import { toDateTime } from '../../lib/formatters';
import { machOperators } from '../../data/volumeflavors';
import MetaBuilder, * as mb from '../../containers/generic/MetaBuilder';
import debugLogger, { LOG_LEVEL_WARN } from '../../lib/debug';
import { volumeFlavorMapper } from '../../services/volumes';

const debug = debugLogger('VolumeFlavor', LOG_LEVEL_WARN);

function metaRuleAdd(props) {
  const m = new MetaBuilder(props);

  const dsAttributes = m.newDataSource(URL_VOLUME_FLAVOR_CLASSIFIER_INFO);
  const dsOperators = m.newStaticDataSource(machOperators);

  m.addField('attribute', 'Attribute')
    .Input()
    .Required()
    .DropDown()
    .DataXform(dsAttributes, ({ rule_attributes: data }) =>
      data.map(({ name }) => ({
        id: name,
        name,
      })),
    );

  m.addField('operator', 'Operator')
    .Input()
    .Required()
    .DropDown()
    .DataXform(dsOperators, (json) => json.map((t) => ({ id: t, name: t })));

  m.addField('value', 'Value').Input().Required();

  return m;
}

function metaClassifierAdd(props) {
  const m = new MetaBuilder(props);

  m.formDefaults((formObj) => {
    const form = formObj;
    form.rules = defaultTo(form.rules, []);
  });

  const origForm = m.view.propDefaultTo('formData', {});
  const dsOrigForm = m.newStaticDataSource(origForm);

  let otherClassifiers = m.parent.view.formDefaultTo('classifiers', []);
  if (!isEmpty(origForm)) {
    otherClassifiers = differenceWith(otherClassifiers, [origForm], isEqual);
  }

  m.addField('name', 'Name').Input().Required().UniqueIn(otherClassifiers);

  m.addSection('Rules').OverviewText(
    `All rules within a classifier must match a volume flavor's attributes
    for a capacity pool to match the classifier (Logical AND).`,
  );

  const rulesTable = m
    .addInputTable('rules', 'Rules')
    .DataXform(dsOrigForm, (json) => json.rules || [])
    .Required();

  rulesTable.AddDialogMeta(metaRuleAdd);
  rulesTable.OnAdd((data) => {
    debug.debug('onAdd(rules)', data);
    dsOrigForm.Push('rules', data);
    m.view.addFormValue('rules', data);
  });

  rulesTable.OnDelete((data) => {
    debug.debug('onDelete(rules)', data);
    dsOrigForm.Remove('rules', data);
    m.view.removeFormValue('rules', data);
  });

  rulesTable.EditDialogMeta(metaRuleAdd);
  rulesTable.OnEdit((nextData, oldData) => {
    debug.debug('onEdit(rules)', oldData, nextData);
    dsOrigForm.Update('rules', oldData, nextData, true);
    m.view.updateFormValue('rules', oldData, nextData, true);
  });

  rulesTable
    .addField('attribute', 'Attribute')
    .CellXform((rowData) => rowData.attribute);

  rulesTable
    .addField('operator', 'Operator')
    .CellXform((rowData) => rowData.operator);

  rulesTable.addField('value', 'Value').CellXform((rowData) => rowData.value);

  return m;
}

function metaCustomAttributes(props) {
  const m = new MetaBuilder(props);

  m.addField('attribute', 'Attribute').Input().Required();
  m.addField('value', 'Value').Input().Required();

  return m;
}

export default function volumeFlavorAdd(props) {
  const m = new MetaBuilder(props);

  m.formDefaults((formObj) => {
    const form = formObj;
    form.classifiers = defaultTo(form.classifiers, []);
    form.details = defaultTo(form.details, {});
    form.details.bullets = defaultTo(form.details.bullets, []);
  });

  const id = m.view.prop('itemId', false);

  let ds;

  debug.debug('got itemId', id);

  if (id === undefined) {
    ds = m.newStaticDataSource({});
  } else {
    ds = m
      .newDataSource(BASE_URL)
      .Item(id)
      .useMapper(volumeFlavorMapper)
      .OnLoad((json) => {
        debug.debug('got volume-flavor:', json);
        const form = json.data;
        form.details = defaultTo(form.details, {});
        form.details.bullets = defaultTo(form.details.bullets, []);
        m.view.initForm(form);
      });
  }

  m.addSection('1').NoChrome().MaxWidth(1200);

  m.addColumn('name', 'Name').Input().Required().MaxWidth(mb.SMALL);

  m.addColumn('description', 'Description').Input().MaxWidth(mb.SMALL);

  m.addColumn('id', 'ID').ReadOnly();
  m.addColumn('created', 'Created')
    .FieldXform((created) => (created ? toDateTime(created) : '--'))
    .ReadOnly();

  m.addColumn('modified', 'Modified')
    .FieldXform((modified) => (modified ? toDateTime(modified) : '--'))
    .ReadOnly();

  m.addField('project_use', 'Visible to project users when creating volumes')
    .Input()
    .MaxWidth(mb.SMALL)
    .CheckBox(false);

  m.addField('hoster_use', 'Visible to hoster users when creating volumes')
    .Input()
    .MaxWidth(mb.SMALL)
    .CheckBox(false);

  m.addSection('Classifiers')
    .MaxWidth(mb.LARGE)
    .OverviewText(`Classifiers are used to select which capacity pools are
    used to provision volumes of this Volume Flavor.
    A volume will be provisioned from any capacity pool
    that matches a classifier (logical OR)`);

  const t = m
    .addInputTable('classifiers', 'Classifier')
    .DataXform(ds, (json) => json.classifiers || [])
    .Required();

  t.AddDialogMeta(metaClassifierAdd);
  t.OnAdd((data) => {
    const form = m.view.getForm();
    const classifiers = m.view.state('form.classifiers');
    debug.debug('onAdd(classifiers): form', form, classifiers, data);

    ds.Push('classifiers', data);
    m.view.addFormValue('classifiers', data);
  });

  t.OnDelete((data) => {
    debug.debug('onDelete(classifiers)', data);
    ds.Remove('classifiers', data);
    m.view.removeFormValue('classifiers', data);
  });

  t.EditDialogMeta(metaClassifierAdd);
  t.OnEdit((nextData, oldData) => {
    debug.debug('onEdit(classifiers)', oldData, nextData);
    ds.Update('classifiers', oldData, nextData, true);
    m.view.updateFormValue('classifiers', oldData, nextData, true);
  });

  const fName = t.addField('name', 'Name').CellXform((rowData) => rowData.name);

  if (m.readOnly) {
    fName.ViewModalItemLink();
  }

  m.addSection('Custom attributes')
    .MaxWidth(mb.LARGE)
    .OverviewText(
      'Set any vendor specific attributes for the volume of this flavor',
    );

  const at = m
    .addInputTable('storage_attributes', 'Attributes')
    .DataXform(ds, (json) => get(json, 'storage_attributes', []));

  at.AddDialogMeta(metaCustomAttributes);
  at.OnAdd((data) => {
    ds.Push('storage_attributes', data);
    m.view.addFormValue('storage_attributes', data);
  });

  at.OnDelete((data) => {
    debug.debug('onDelete(storage_attributes)', data);
    ds.Remove('storage_attributes', data);
    m.view.removeFormValue('storage_attributes', data);
  });

  at.EditDialogMeta(metaCustomAttributes);
  at.OnEdit((nextData, oldData) => {
    debug.debug('onEdit(storage_attributes)', oldData, nextData);
    ds.Update('storage_attributes', oldData, nextData, true);
    m.view.updateFormValue('storage_attributes', oldData, nextData, true);
  });

  at.addField('attribute', 'Attribute').CellXform(
    (rowData) => rowData.attribute,
  );

  at.addField('value', 'Value').CellXform((rowData) => rowData.value);

  const dsProjects = m.newDataSource(URL_PROJECTS);
  m.addSection('Restricted use').MaxWidth(mb.LARGE).OverviewText(
    // 'Set restricted use for this volume-flavor to the selected
    //   projects below. If no restrictions are set the
    //   volume-flavor is available to anyone.',
  );
  m.addColumn('permitted_projects', 'Permitted')
    .Input()
    .MultiSelect()
    .DataXform(dsProjects, (json) =>
      json.map((v) => ({ id: v.id, name: v.name })),
    )
    .Width(mb.SMALL);

  return m;
}
