// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import defaultTo from 'lodash/defaultTo';
import differenceWith from 'lodash/differenceWith';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import matches from 'lodash/matches';
import auth from '../../lib/auth';
import * as c from '../../routes/consts.js';
import { toDateTime } from '../../lib/formatters';
import MetaBuilder, {
  ValidationResult,
  SMALL,
  LARGE,
} from '../../containers/generic/MetaBuilder';
import ColumnLink from '../../components/Griddle/ColumnLink';
import ColumnFormatter from '../../components/Griddle/ColumnFormatter';
import ItemViewContainer from '../../containers/generic/ItemViewContainer';
import CreateViewContainer from '../../containers/generic/CreateViewContainer';
import EditViewContainer from '../../containers/generic/EditViewContainer';
import { machOperators, subattributeOperators } from '../../data/machine.js';
import {
  ENCODING_BASE64,
  ENCODING_HEX,
  ENCODING_NONE,
  ENCODINGS,
} from '../../data/encoding.js';
import { REDISCOVER, REDISCOVER_NOT_REQUIRED } from '../../data/rediscover.js';
import { VERSION_MASK } from '../../data/regex';
import debugLogger, * as log from '../../lib/debug';
import FormInlineSubattributes from '../../containers/generic/MetaForm/FormInlineSubattributes';
import { urlIcon } from '../../utils';
import { LABEL_MACHINETYPES } from '../../components/HybridNav/consts.js';
import { machineTypesMapper } from '../../services/machineTypes.js';

const debug = debugLogger('MachineType', log.LOG_LEVEL_WARN);

const EXTERNAL_NAME = c.URL_MACHINETYPES;
const DS_SYSTEM_SERVICES = 'system-services';
const DS_FWBASELINES = 'fwbaselines';
const DS_USER_OPS = 'userops';

const STATE_INITIALPREP = 'Initial Prep';
const STATE_DISCOVER = 'Discover';
const STATE_PREP = 'Prep';
const STATE_CLEANUP = 'Cleanup';
const STATE_MAINTENANCE = 'Maintenance';

export const settings = {
  authUrl: EXTERNAL_NAME, // used to filter create/trash icons from the view; using the defined roledef permissions mappings to this auth URL entry
  homeUrl: c.makeSecUrl(EXTERNAL_NAME), // homepage for this list view (e.g. /s/hosters/:pid/) is where this view is located; for return from create / item views
  homeLabel: LABEL_MACHINETYPES,
  baseUrl: EXTERNAL_NAME, // base url to be used for creating all associated URLS for this reasource, e.g. pageItem, pageCreate, restUrl, restItemUrl
  stateKey: EXTERNAL_NAME,
};

function metaList(props) {
  const m = new MetaBuilder(props);

  m.addRowClick((navigate) => ({ datum: { id } }) => {
    navigate(c.setId(c.makeSecItemUrl(EXTERNAL_NAME), id));
  });

  m.addColumn('name', 'Name')
    .Custom(ColumnLink, {
      info: (rowData) => {
        const url = c.setId(c.makeSecItemUrl(EXTERNAL_NAME), rowData.id);
        const { name } = rowData;
        return { url, name };
      },
    })
    .Default()
    .Input()
    .MaxLength(150)
    .Required();
  m.addColumn('description', 'Description').Default();
  m.addColumn('fw_baseline_version_default', 'FW baseline version').Default();
  m.addColumn('weight', 'Weight').Default();

  m.addColumn('created', 'Created');
  m.addColumn('modified', 'Modified');
  m.addColumn('id', 'ID');

  if (auth.inScope(c.SCOPE_PORTAL)) {
    m.addColumn('hoster_published', 'Published')
      .Custom(ColumnFormatter, {
        format: (rowData) => (rowData.hoster_id === '*' ? 'yes' : 'no'),
      })
      .Default();
  }

  return m;
}

function metaCreate(props) {
  const m = new MetaBuilder(props);

  m.formDefaults((form) => {
    form.classifiers = defaultTo(form.classifiers, []);
    form.prep = defaultTo(form.prep, []);
    form.cleanup = defaultTo(form.cleanup, []);
  });

  const id = m.view.prop('itemId', false);

  let ds;

  debug.debug('got itemId', id);

  const dsServices = m
    .newDataSource(c.URL_SERVICES, DS_SYSTEM_SERVICES)
    .Filter((v) => v.type === 'system' && v.hoster_id !== '*');

  const dsFWBaselines = m.newDataSource(c.URL_FWBASELINES, DS_FWBASELINES);

  if (id === undefined) {
    ds = m.newStaticDataSource({});
  } else {
    ds = m
      .newDataSource(c.URL_MACHINETYPES)
      .Item(id)
      .useMapper(machineTypesMapper)
      .OnLoad((json) => {
        debug.debug('got machinetype:', json);
        m.view.initForm(json.data);
      });
  }

  m.addSection('1').NoChrome().MaxWidth(LARGE);

  m.addColumn('name', 'Name').Input().Required();

  m.addColumn('description', 'Description').Input();

  m.addColumn('id', 'ID').ReadOnly();
  m.addColumn('created', 'Created')
    .FieldXform((created) => (created ? toDateTime(created) : '--'))
    .ReadOnly();
  m.addColumn('modified', 'Modified')
    .FieldXform((modified) => (modified ? toDateTime(modified) : '--'))
    .ReadOnly();

  m.addColumn('weight', 'Weight')
    .Input(50)
    .Number(1, 100, 'Must be an integer value in the range 1 to 100.')
    .Required();

  // ----- Classifier Section -----

  m.addSection('Classifiers')
    .MaxWidth(LARGE)
    .OverviewText(
      'Classifiers are used to select which machines belong to this Machine Type. A machine will be assigned the Machine Type that has any matching classifier (logical OR) and the highest weight value'
    );

  let t = m
    .addInputTable('classifiers', 'Classifier')
    .DataXform(ds, (json) => json.classifiers || [])
    .Required();

  t.AddDialogMeta(metaClassifierAdd, 'large');
  t.OnAdd((data) => {
    const form = m.view.getForm();

    const classifiers = m.view.state('form.classifiers');
    debug.debug('onAdd(classifiers): form', form, classifiers, data);

    ds.Push('classifiers', data); // add to dataset used to render the page
    m.view.addFormValue('classifiers', data); // add to form to be posted/put
  });

  t.OnDelete((data) => {
    debug.debug('onDelete(classifiers)', data);

    // remove classifier
    const match = matches(data); //  <<<<<<<<<<<<<<<<<<<<<<<<<<< name field
    ds.Remove('classifiers', match);
    m.view.removeFormValue('classifiers', match);
  });

  t.EditDialogMeta(metaClassifierAdd, 'large'); // use same for now
  t.OnEdit((nextData, oldData) => {
    debug.debug('onEdit(classifiers)', oldData, nextData);

    const match = matches(oldData); // <<<<<<<<<<<<<<<<<<<<<<<<<<<< name field
    ds.Update('classifiers', match, nextData, true);
    m.view.updateFormValue('classifiers', match, nextData, true);
  });

  const fName = t.addField('name', 'Name').CellXform((rowData) => rowData.name);

  if (m.readOnly) {
    fName.ViewModalItemLink();
  }

  // ----- Firmware Baseline Section -----

  m.addSection('Firmware baselines')
    .MaxWidth(LARGE)
    .OverviewText(
      'Firmware Baselines are used to indicate the default and minimum firmware versions that the machines of this type needs to be.'
    );

  m.addColumn('fw_baseline_version_min', 'Minimum FW version')
    .Input()
    .Mask(VERSION_MASK);
  m.addColumn('fw_baseline_id_default', 'Default FW baseline ID')
    .Input()
    .MaxWidth(SMALL)
    .DropDown()
    .DataXform(dsFWBaselines, (json) =>
      json?.length
        ? json.map((fwBaselineData) => ({
            id: fwBaselineData.id,
            name: `${fwBaselineData.name}`,
          }))
        : []
    )
    .OnChange((id) => {
      m.view.setFormValue(
        'fw_baseline_version_default',
        dsFWBaselines.Data().find((fw) => fw?.id === id)?.version
      );
    })
    .ShowLink();
  m.addColumn('fw_baseline_version_default', 'Default FW baseline version')
    .Mask(VERSION_MASK)
    .Input()
    .Hidden();

  // ----- InitialPrep Steps (v2) -----

  let title = 'Initial preparation steps';
  let overviewText =
    'Initial prep steps are used to apply BMC-centric operations to prepare a server prior to discovery.  This may include iLO firmware udpate, setting BIOS parameters, setting iLO license, etc.';
  let path = 'user_defined_steps.initprep_steps';
  let addDialogMeta = metaHandlerInitPrepStepsAdd;
  userDefinedStepsSection(m, ds, title, overviewText, path, addDialogMeta);

  // ----- Discover Steps (v2) -----

  title = 'Discover steps';
  overviewText =
    'Discover steps are the specific sequence of operations to discover the machine characteristics.  Typically this should include a boot to the service OS and a discover op.';
  path = 'user_defined_steps.discover_steps';
  addDialogMeta = metaHandlerDiscoverStepsAdd;
  userDefinedStepsSection(m, ds, title, overviewText, path, addDialogMeta);

  // ----- Prep Steps (v2) -----

  title = 'Preparation steps';
  overviewText =
    'Prep steps are used to prepare a Machine for inventory after it is initially discovered. This may include updates to system firmware, configuring a RAID controller, or other steps.';
  path = 'user_defined_steps.prep_steps';
  addDialogMeta = metaHandlerPrepStepsAdd;
  userDefinedStepsSection(m, ds, title, overviewText, path, addDialogMeta);

  // ----- Cleanup Steps (v2) -----

  title = 'Cleanup steps';
  overviewText =
    'Cleanup steps are used to sanitize a Machine after it has been used. This may include clearing the disk contents.';
  path = 'user_defined_steps.cleanup_steps';
  addDialogMeta = metaHandlerCleanupStepsAdd;
  userDefinedStepsSection(m, ds, title, overviewText, path, addDialogMeta);

  // ----- Maintenance Steps (v2) -----

  title = 'Maintenance steps';
  overviewText =
    'Maintenance steps are used to perform maintenance actions on a Machine. This may include updating firmware.';
  path = 'user_defined_steps.mtc_steps';
  addDialogMeta = metaHandlerMaintenanceStepsAdd;

  userDefinedStepsSection(m, ds, title, overviewText, path, addDialogMeta);

  // ----- Prep Steps (v1 - DEPRECATED) -----

  m.addSection('Prep steps - DEPRECATED')
    .MaxWidth(LARGE)
    .OverviewText(
      'Prep services are used to prepare a Machine for inventory after it is initially discovered. This may include updates to system firmware, configuring a RAID controller, or other steps.'
    )
    .Visible(() => get(ds.Data(), 'prep', []).length > 0); // only show if not empty

  t = m
    .addInputTable('prep', 'Prep steps')
    .DataXform(ds, (json) => json.prep || []);

  t.OnUpDown((action, data) => {
    const match = matches(data);
    if (ds.UpDown('prep', action, match)) {
      m.view.setFormValue('prep', ds.Data().prep);
    }
  });

  t.AddDialogMeta(metaHandlerPrepAdd);
  t.OnAdd((data) => {
    const form = m.view.getForm();

    const handlers = m.view.formDefaultTo('prep', []);
    debug.debug('onAdd(prep): form', form, handlers, data);

    ds.Push('prep', data); // add to dataset used to render the page
    m.view.addFormValue('prep', data); // add to form to be posted/put
  });

  t.OnDelete((data) => {
    debug.debug('onDelete(prep)', data);

    // remove prep handler
    const match = matches(data);
    ds.Remove('prep', match);
    m.view.removeFormValue('prep', match);
  });

  t.EditDialogMeta(metaHandlerPrepAdd); // use same for now
  t.OnEdit((nextData, oldData) => {
    debug.debug('onEdit(prep)', oldData, nextData);

    const match = matches(oldData);
    ds.Update('prep', match, nextData, true);
    m.view.updateFormValue('prep', match, nextData, true);
  });

  t.addField('stage', 'Step').CellXform((rowData) => rowData.stage);

  t.addField('service_id', 'Service').CellXform((rowData) => {
    let name = '';
    const id = rowData.service_id;
    const data = dsServices.Data();
    const item = data.find((v) => v.id === id);
    if (item !== undefined) {
      name = item.name;
    }
    return name;
  });

  // ----- Cleanup Steps (v1 - DEPRECATED) -----
  m.addSection('Cleanup steps - DEPRECATED')
    .MaxWidth(LARGE)
    .OverviewText(
      'Cleanup services are used to sanitize a Machine after it has been used. This may include clearing the disk contents.'
    )
    .Visible(() => get(ds.Data(), 'cleanup', []).length > 0); // only show if not empty

  t = m
    .addInputTable('cleanup', 'Cleanup steps')
    .DataXform(ds, (json) => json.cleanup || []);

  t.OnUpDown((action, data) => {
    const match = matches(data);
    if (ds.UpDown('cleanup', action, match)) {
      m.view.setFormValue('cleanup', ds.Data().cleanup);
    }
  });

  t.AddDialogMeta(metaHandlerCleanupAdd);

  t.OnAdd((data) => {
    const form = m.view.getForm();

    const handlers = m.view.formDefaultTo('cleanup', []);
    debug.debug('onAdd(cleanup): form', form, handlers, data);

    ds.Push('cleanup', data); // add to dataset used to render the page
    m.view.addFormValue('cleanup', data); // add to form to be posted/put
  });

  t.OnDelete((data) => {
    debug.debug('onDelete(cleanup)', data);

    // remove prep handler
    const match = matches(data);
    ds.Remove('cleanup', match);
    m.view.removeFormValue('cleanup', match);
  });

  t.EditDialogMeta(metaHandlerCleanupAdd); // use same for now
  t.OnEdit((nextData, oldData) => {
    debug.debug('onEdit(cleanup)', oldData, nextData);

    const match = matches(oldData);
    ds.Update('cleanup', match, nextData, true);
    m.view.updateFormValue('cleanup', match, nextData, true);
  });

  t.addField('stage', 'Step').CellXform((rowData) => rowData.stage);

  t.addField('service_id', 'Service').CellXform((rowData) => {
    let name = '';
    const id = rowData.service_id;
    const data = dsServices.Data();
    const item = data.find((v) => v.id === id);
    if (item !== undefined) {
      name = item.name;
    }
    return name;
  });

  return m;
}

function metaHandlerPrepAdd(props) {
  return metaHandlerAdd(props, 'prep');
}

function metaHandlerCleanupAdd(props) {
  return metaHandlerAdd(props, 'cleanup');
}

function metaHandlerAdd(props, key) {
  const m = new MetaBuilder(props);

  const origForm = m.view.propDefaultTo('formData', {});

  let other_handlers = m.parent.view.formDefaultTo(key, []);

  if (!isEmpty(origForm)) {
    other_handlers = differenceWith(other_handlers, [origForm], isEqual);
  }

  const dsEncoding = m.newStaticDataSource(ENCODINGS);
  const dsRediscover = m.newStaticDataSource(REDISCOVER);
  const dsServices = m
    .newDataSource(c.URL_SERVICES, DS_SYSTEM_SERVICES)
    .Filter((v) => v.type === 'system' && v.hoster_id !== '*');

  m.addField('stage', 'Step name').Input().Required().UniqueIn(other_handlers);

  m.addField('service_id', 'Service')
    .Input()
    .Required()
    .DropDown()
    .DataXform(dsServices, (json) =>
      json.map((t) => ({ id: t.id, name: t.name }))
    );

  m.addField('rediscover_required', 'Rediscover required')
    .Input(REDISCOVER_NOT_REQUIRED)
    .Required()
    .DropDown()
    .DataXform(dsRediscover, (json) => json.map((t) => ({ id: t, name: t })));

  m.addField('encoding', 'Service data encoding')
    .Input()
    .Required()
    .DropDown()
    .DataXform(dsEncoding, (json) => json.map((t) => ({ id: t, name: t })));

  m.addField('service_data', 'Service data')
    .Input()
    .TextArea()
    .CustomValidator(() => {
      const encoding = m.view.formDefaultTo('encoding');
      const data = m.view.formDefaultTo('service_data');
      switch (encoding) {
        case ENCODING_NONE:
          return new ValidationResult(true, '');
        case ENCODING_HEX:
          // biome-ignore lint/correctness/noSwitchDeclarations: <explanation>
          const hexregex = /^([0-9a-fA-F]{2})*$/;
          if (!hexregex.test(data)) {
            return new ValidationResult(
              false,
              'Data is not hex (base16) encoded.'
            );
          }
          return new ValidationResult(true, '');
        case ENCODING_BASE64:
        default:
          const base64regex =
            /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
          if (!base64regex.test(data)) {
            return new ValidationResult(false, 'Data is not base64 encoded.');
          }
          return new ValidationResult(true, '');
      }
    });

  return m;
}

function metaClassifierAdd(props) {
  const m = new MetaBuilder(props);

  m.formDefaults((form) => {
    form.rules = defaultTo(form.rules, []);
  });

  // if edit, then remove item being edited from unique set
  const origForm = m.view.propDefaultTo('formData', {});
  const dsOrigForm = m.newStaticDataSource(origForm);

  // unique name for classifier
  let other_classifiers = m.parent.view.formDefaultTo('classifiers', []);
  if (!isEmpty(origForm)) {
    other_classifiers = differenceWith(other_classifiers, [origForm], isEqual);
  }

  m.addField('name', 'Name').Input().Required().UniqueIn(other_classifiers);

  // rules
  m.addSection('Rules').OverviewText(
    "All rules within a classifier must match a machine's attributes for a machine to match the classifier (Logical AND).",
  );

  const rulesTable = m
    .addInputTable('rules', 'Rules')
    .DataXform(dsOrigForm, (json) => json.rules || [])
    .Required();

  rulesTable.AddDialogMeta(metaRuleAdd, 'large');
  rulesTable.OnAdd((data) => {
    debug.debug('onAdd(rules)', data);
    dsOrigForm.Push('rules', data);
    m.view.addFormValue('rules', data);
  });

  rulesTable.OnDelete((data) => {
    debug.debug('onDelete(rules)', data);

    const ruleMatch = matches(data);
    dsOrigForm.Remove('rules', ruleMatch);
    m.view.removeFormValue('rules', ruleMatch);
  });

  rulesTable.EditDialogMeta(metaRuleAdd, 'large');
  rulesTable.OnEdit((nextData, oldData) => {
    debug.debug('onEdit(rules)', oldData, nextData);

    const ruleMatch = matches(oldData);
    debug.debug('onEdit ruleMatch value: ', ruleMatch);
    dsOrigForm.Update('rules', ruleMatch, nextData, true);
    m.view.updateFormValue('rules', ruleMatch, nextData, true);
  });

  // fields for the rules

  /*
  rulesTable.addField('name', 'Rule')
    .CellXform( (rowData) => rowData.name )
  */
  rulesTable
    .addField('attribute', 'Attribute')
    .CellXform((rowData) => rowData.attribute);

  rulesTable
    .addField('operator', 'Operator')
    .CellXform((rowData) => rowData.operator);

  rulesTable.addField('value', 'Value').CellXform((rowData) => rowData.value);

  rulesTable
    .addField('subattributes', '') // Hide column but pass the data
    .Custom()
    .CellXform((rowData) => rowData.subattribute_rules);

  return m;
}

function metaRuleAdd(props) {
  const m = new MetaBuilder(props);

  const machineTypesClassifierinfo = m.newDataSource(
    c.URL_MACHINE_TYPE_CLASSIFIER_INFO,
  );
  const dsOperators = m.newStaticDataSource(machOperators);

  const hasSubattributes = () => {
    const { attribute } = m.view.getForm();
    const { rule_attributes } = machineTypesClassifierinfo.Data() || {
      rule_attributes: [],
    };
    const attributeData = rule_attributes.find(
      ({ name }) => attribute === name,
    );
    return attributeData?.has_subattributes;
  };

  const withSubattributes = () => hasSubattributes();
  const withoutSubattributes = () => !hasSubattributes();

  // other rules within this switch set
  let other_rules = m.parent.view.formDefaultTo('rules', []);
  // if edit, then remove item being edited from unique set
  const origForm = m.view.prop('formData', false);
  if (!isNil(origForm)) {
    other_rules = differenceWith(other_rules, [origForm], isEqual);
  }
  debug.debug('other_rules', other_rules);

  // TODO: validate unique rule for all 3 attributes

  // m.addField('name', 'Name').Input().Required().UniqueIn(other_rules)

  m.addField('attribute', 'Attribute')
    .Input()
    .Required()
    .DropDown()
    .DataXform(machineTypesClassifierinfo, (json) =>
      json.rule_attributes.map((t) => ({ ...t, id: t.name })),
    )
    .OnInit(() => {
      if (hasSubattributes()) {
        dsOperators.SetData(['match']);
      }
    })
    .OnChange(() => {
      if (hasSubattributes()) {
        m.view.setFormValue('operator', 'match');
        dsOperators.SetData(['match']);
      } else {
        m.view.setFormValue('subattribute_rules', []);
        dsOperators.SetData(machOperators);
      }
    });

  m.addField('operator', 'Operator')
    .Input()
    .Required()
    .DropDown()
    .DataXform(dsOperators, (json) => json.map((t) => ({ id: t, name: t })))
    .CustomValidator((value) => {
      if (withSubattributes() && value !== 'match') {
        return new ValidationResult(
          false,
          "'match' is only allowed for attribute with subattributes",
        );
      }

      return new ValidationResult(true, '');
    });

  m.addField('value', 'Value')
    .Input()
    .RequiredSometimes(withoutSubattributes)
    .Visible(withoutSubattributes);

  m.addField('subattribute_rules', 'Subattributes')
    .Input()
    .Custom(FormInlineSubattributes, {
      operators: subattributeOperators,
      attributes: machineTypesClassifierinfo,
    })
    .Visible(withSubattributes)
    .CustomValidator((value) => {
      if (
        !value ||
        !value.length ||
        (value.length &&
          value.every((row) => Object.keys(row).every((key) => !!row[key])))
      ) {
        return new ValidationResult(true);
      }
      const validations = value.map((subattribute) =>
        Object.keys(subattribute).reduce(
          (acc, key) =>
            !subattribute[key] ? { ...acc, [key]: `${key} is required` } : acc,
          {},
        ),
      );

      return new ValidationResult(false, validations);
    });

  return m;
}

//------------------------------------------------------------------------------------
// ----- v2 User Defined Ops -----
//------------------------------------------------------------------------------------

// userDefinedStepsSection provides a consistent way of creating the GUI for each
// of the hoster-defined Op sequence steps (states)
function userDefinedStepsSection(
  m,
  ds,
  title,
  overviewText,
  path,
  addDialogMeta,
) {
  m.addSection(title).MaxWidth(LARGE).OverviewText(overviewText);

  const t = m
    .addInputTable(path, title)
    .DataXform(ds, (json) => get(json, path, []));

  t.OnUpDown((action, data) => {
    const match = matches(data);
    if (ds.UpDown(path, action, match)) {
      m.view.setFormValue(path, get(ds.Data(), path));
    }
  });

  t.AddDialogMeta(addDialogMeta);
  t.OnAdd((data) => {
    const form = m.view.getForm();

    const steps = m.view.formDefaultTo(path, []);
    debug.debug('onAdd(): form', form, steps, data);

    ds.Push(path, data); // add to dataset used to render the page
    m.view.addFormValue(path, data); // add to form to be posted/put
  });

  t.OnDelete((data) => {
    debug.debug('onDelete()', data);

    // remove prep handler
    ds.Remove(path, data);
    m.view.removeFormValue(path, data);
  });

  t.EditDialogMeta(addDialogMeta); // use same for now
  t.OnEdit((nextData, oldData) => {
    debug.debug('onEdit()', oldData, nextData);

    ds.Update(path, oldData, nextData, true);
    m.view.updateFormValue(path, oldData, nextData, true);
  });

  t.addField('operation', 'Operation').CellXform(
    (rowData) => rowData.operation,
  );

  t.addField('parameters', 'Parameters').CellXform(
    (rowData) =>
      rowData.parameters &&
      rowData.parameters.map(({ name, value }) => `${name}=${value}`),
  );
}

function metaHandlerInitPrepStepsAdd(props) {
  return metaUserOpsAdd(
    props,
    'user_defined_steps.initprep_steps',
    STATE_INITIALPREP,
  );
}

function metaHandlerDiscoverStepsAdd(props) {
  return metaUserOpsAdd(
    props,
    'user_defined_steps.discover_steps',
    STATE_DISCOVER,
  );
}

function metaHandlerPrepStepsAdd(props) {
  return metaUserOpsAdd(props, 'user_defined_steps.prep_steps', STATE_PREP);
}

function metaHandlerCleanupStepsAdd(props) {
  return metaUserOpsAdd(
    props,
    'user_defined_steps.cleanup_steps',
    STATE_CLEANUP,
  );
}

function metaHandlerMaintenanceStepsAdd(props) {
  return metaUserOpsAdd(
    props,
    'user_defined_steps.mtc_steps',
    STATE_MAINTENANCE,
  );
}

function metaUserOpsAdd(props, key, state) {
  const m = new MetaBuilder(props);

  const origForm = m.view.prop('formData', false);
  const ds = m.newStaticDataSource(origForm);

  // List of supported user ops in the given state. Note that since "states"
  // have been declared in the golang file with "omitempty", we must check for
  // the existence of the "states" map first, before checking for the existence
  // of the (state) key in the map.
  const dsUserOps = m
    .newDataSource(c.URL_USER_OPS, DS_USER_OPS)
    .Filter((v) => v.states && v.states[state] === true);

  m.addField('operation', 'Operation')
    .Input()
    .Required()
    .DropDown()
    .DataXform(dsUserOps, (json) =>
      json.map((t) => ({
        id: t.operation,
        name: t.operation,
        info: t.description,
      })),
    )
    .OnChange(() => {
      const userOp = dsUserOps
        .Data()
        .find((v) => v.operation === m.view.formDefaultTo('operation'));

      const opArgs = defaultTo(userOp.parameters, []).map((opArg) => ({
        ...opArg,
      }));

      m.view.setFormValue('description', userOp.description);
      m.view.setFormValue('parameters', opArgs);
      ds.Set('parameters', opArgs);
    });

  const t = opParameterTable(m, ds);

  return m;
}

// display op parameters and allow input
function opParameterTable(m, ds) {
  const t = m
    .addInputTable('parameters', 'Parameters')
    .DataXform(ds, (json) => defaultTo(get(json, 'parameters', []), []));

  t.EditDialogMeta(metaOpArgsEdit);
  t.OnEdit((nextData, oldData) => {
    debug.debug('onEdit()', oldData, nextData);

    ds.Update('parameters', oldData, nextData, true);
    m.view.updateFormValue('parameters', oldData, nextData, true);
  });

  t.addField('name', 'Parameter Name')
    .Input()
    .CellXform(({ name }) => name);

  t.addField('secure_value', '')
    .Input()
    .CellXform(({ secure_value, type, value }) =>
      type === 'URL'
        ? urlIcon({ secure_url: secure_value, display_url: value })
        : '',
    );

  t.addField('value', 'Value')
    .Input()
    .CellXform(({ value }) => value);

  return t;
}

function metaOpArgsEdit(props) {
  const m = new MetaBuilder(props);

  const origForm = m.view.prop('formData', false);
  const form = m.view.getForm();

  // Populate based on the selected Operation's "Description".
  m.addField('description', 'Description').Input().ReadOnly();

  m.addField('value', origForm.name).Input().Required();

  if (form.type === 'URL') {
    m.addField('secure_value', 'Secure URL').Input();
  }

  return m;
}

export { default as MachineTypeListView } from './MachineTypeListView';

export const MachineTypeItemView = ItemViewContainer({
  ...settings,
  allowEdit: true,
  meta: (props) => metaCreate(props),
  title: 'Machine type',
});

export const MachineTypeCreateView = CreateViewContainer({
  ...settings,
  meta: (props) => metaCreate(props),
  title: 'Create machine type',
});

export const MachineTypeEditView = EditViewContainer({
  ...settings,
  meta: (props) => metaCreate(props),
  title: 'Edit machine type',
});
