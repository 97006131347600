// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import { sprintf } from 'sprintf-js';
import rest from '../../lib/rest';
import assert from '../../lib/assert';
import debugLogger from '../../lib/debug';
import ViewHeader from '../../components/ViewHeader';
import View from '../../components/View';
import * as c from '../../routes/consts.js';
import * as mb from './MetaBuilder';
import MetaForm from './MetaForm';
import ErrorDialog from '../../components/controls/ErrorDialog';
import { EDGE_RACK } from '../../data/rack';

const debugEnabled = false;

const assertValidSettings = (s) => {
  assert.defined(s.title, 'title');
  assert.defined(s.homeUrl, 'homeUrl');
  assert.defined(s.baseUrl, 'baseUrl');
  assert.defined(s.authUrl, 'authUrl');
  assert.defined(s.meta, 'meta()', ', check the meta file for this view');
};

export default function CreateViewContainer(settings) {
  const className = `CreateViewContainer<${settings.title}>`;
  const debug = debugLogger(className, debugEnabled);
  assertValidSettings(settings);

  class CreateViewContainer extends React.Component {
    static propTypes = {
      history: PropTypes.object,
      confirmComponent: PropTypes.object,
    };

    constructor(props) {
      super(props);
      debug.debug('settings:', settings);
      debug.debug('props:', props);
      this.state = {
        errDialog: { show: false },
      };
    }

    UNSAFE_componentWillMount() {
      this.homeUrl = settings.homeUrl;
      this.restBaseUrl = c.makeRestUrl(settings.baseUrl);

      // if a subresource then adjust urls
      if (this.props.match.params?.pid) {
        this.homeUrl = c.setParentId(
          settings.homeUrl,
          this.props.match.params.pid,
        );
        this.restBaseUrl = c.makeRestUrl(
          c.setParentId(settings.baseUrl, this.props.match.params.pid),
        );
      }

      if (!isNil(settings.createUrl)) {
        this.restCreateItemUrl = settings.createUrl;
      } else {
        this.restCreateItemUrl = () => this.restBaseUrl;
      }
    }

    cancelScreen = () => {
      debug.log('cancelScreen');
      this.props.history.push(this.homeUrl);
    };

    submit = (data) => {
      if (data.bmc?.ip_pool_id === 'new_ip_pool') {
        const { payload, ...restData } = data;

        return rest
          .post('/rest/ippools', payload)
          .then((res) => res.json())
          .then((ippool) =>
            rest.post(this.restCreateItemUrl(data), {
              ...restData,
              bmc: {
                ...restData.bmc,
                ip_pool_id: ippool.id,
              },
            }),
          )
          .then((res) => this.handleSubmitResults(res))
          .catch((err) =>
            rest.errorInfo(err, (errInfo) => {
              this.showErrorDialog('Create error', errInfo.text);
            }),
          );
      }

      if (data.ip_pool === 'new') {
        const { ip_pool, payload, ...restData } = data;

        return rest
          .post('/rest/ippools', payload)
          .then((res) => res.json())
          .then((ippool) =>
            rest.post(this.restCreateItemUrl(data), {
              ...restData,
              ip_pool_id: ippool.id,
            }),
          )
          .then((res) => this.handleSubmitResults(res))
          .catch((err) =>
            rest.errorInfo(err, (errInfo) => {
              this.showErrorDialog('Create error', errInfo.text);
            }),
          );
      }

      if (data.new_host) {
        return rest
          .post(this.restCreateItemUrl(data), data.new_host)
          .then((res) => this.handleSubmitResults(res))
          .catch((err) =>
            rest.errorInfo(err, (errInfo) => {
              this.showErrorDialog('Create error', errInfo.text);
            }),
          );
      }

      // Post to database
      const payload = { ...data };

      const restUrl = this.restCreateItemUrl(data);

      if (restUrl === '/rest/racktemplates') {
        let portUsage = payload.switch_sets[0].port_usage2;

        if (portUsage) {
          portUsage = Object.keys(portUsage).reduce(
            (acc, key) => ({
              // biome-ignore lint/performance/noAccumulatingSpread: <explanation>
              ...acc,
              [key]:
                key === '__uniqueid'
                  ? portUsage[key]
                  : portUsage[key].filter(
                      ({ speed, ports }) => !!speed && !!ports.length,
                    ),
            }),
            {},
          );

          payload.switch_sets[0].port_usage2 = portUsage;
        }
      }

      if (
        restUrl === c.makeRestUrl(c.URL_RACKS) &&
        payload.type === EDGE_RACK
      ) {
        payload.unmanaged_switch = true;
      }

      if (restUrl === c.makeRestUrl(c.URL_VOLUMES)) {
        if (payload.storage_pool_id === 'auto') {
          payload.storage_pool_id = '';
        }

        if (payload.volumeCollectionID === 'none') {
          payload.volumeCollectionID = '';
        }

        payload.capacity = Math.round(payload.capacity * 976562.5); // convert user input from GB to KiB for Operator API call.
      }

      return rest
        .post(restUrl, payload)
        .then((res) => this.handleSubmitResults(res))
        .catch((err) =>
          rest.errorInfo(err, (errInfo) => {
            this.showErrorDialog('Create error', errInfo.text);
          }),
        );
    };

    showErrorDialog = (title, body) => {
      this.setState({ errDialog: { show: true, title, body } });
    };

    handleSubmitResults = (res) => {
      debug.debug('handleSubmitResults', res);
      if (!res.ok) {
        this.showErrorDialog(
          'Error',
          sprintf(
            'Error found during submit: %s %s.',
            res.status,
            res.statusText,
          ),
        );
      } else if (isNil(this.props.confirmComponent)) {
        res.json().then((json) => {
          this.cancelScreen();
        });
      } else {
        res.json().then((json) => {
          this.setState({ createResult: json });
          debug.log(json);
        });
      }
    };

    render() {
      if (
        !isNil(this.props.confirmComponent) &&
        !isNil(this.state) &&
        !isNil(this.state.createResult)
      ) {
        return React.createElement(this.props.confirmComponent, {
          exit: this.cancelScreen,
          result: this.state.createResult,
        });
      }

      return (
        <View>
          <ErrorDialog
            key='errDialog'
            show={this.state.errDialog.show}
            title={this.state.errDialog.title}
            body={this.state.errDialog.body}
            onDismiss={() => this.setState({ errDialog: { show: false } })}
          />
          <ViewHeader title icon={settings.icon}>
            {settings.title}
            <span />
            {/* make children an array for prop validation */}
          </ViewHeader>
          <MetaForm
            meta={settings.meta}
            className={className}
            mode={mb.MODE_CREATE}
            submitLabel='Create'
            onSubmit={this.submit}
            cancelLabel='Cancel'
            onCancel={this.cancelScreen}
          />
        </View>
      );
    }
  }

  return CreateViewContainer;
}
