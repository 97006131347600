// (C) Copyright 2023-2024 Hewlett Packard Enterprise Development LP

import React, { useState } from 'react';
import { Text, Box, Button, Notification, Layer, Heading, Grid } from 'grommet';
import { CircleAlert, Close } from 'grommet-icons';
import { isEmpty } from 'lodash';
import { useNotificationInlineContext } from '../utils/context/notificationInlineContext';
import { keyer, trimText } from '../utils';

export const ErrorsModal = ({ itemName, error, onClose }) => {
  const key = keyer();
  return (
    <Box pad='medium' gap='medium'>
      <Box direction='row' align='start' justify='between' gap='medium'>
        <Heading level={2}>{`Failed ${itemName}`}</Heading>
        <Button alignSelf='center' icon={<Close />} onClick={onClose} />
      </Box>
      <Box gap='small' overflow='auto' style={{ maxHeight: '600px' }}>
        {error.map((item, index) => (
          <Grid key={key.next()}>
            <Text weight='bold'>{item.name}</Text>
            <Text>{item.errorMessage}</Text>
          </Grid>
        ))}
      </Box>
    </Box>
  );
};

export const ErrorsAction = {
  delete: { title: 'Deleted', verb: 'to delete' },
  add: { title: 'Added', verb: 'to add' },
};

/**
 * Component to display inline notification for errors and failures.
 *
 * status - The status object containing success and error arrays.
 */
export const ErrorsFailInlineNotification = ({
  status,
  itemTitle,
  errorsAction = ErrorsAction.delete,
}) => {
  const { handleClose } = useNotificationInlineContext();
  const { success = [], error = [] } = status;
  const [showErrors, setShowErrors] = useState(false);
  const baseName = itemTitle.toLowerCase();
  const successNames = success.slice(0, 3).reduce((acc, item) => {
    const comma = isEmpty(acc) ? '' : ', ';
    return `${acc}${comma}${trimText(item.name, 22)}`;
  }, '');

  const errorNames = error.slice(0, 3).reduce((acc, item) => {
    const comma = isEmpty(acc) ? '' : ', ';
    return `${acc}${comma}${trimText(item.name, 22)}`;
  }, '');

  const total = success.length + error.length;

  const title = `${errorsAction.title} ${success.length} of ${total} selected ${baseName}${
    isEmpty(success) ? '.' : `: ${successNames}.`
  }`;

  return (
    <>
      <Notification
        onClose={handleClose}
        icon={<CircleAlert />}
        title={title}
        message={
          <Box align='start'>
            {`Failed ${errorsAction.verb}: ${errorNames}${error.length > 3 ? ` +${error.length - 3} more` : ''}.`}
            <Button
              style={{ padding: '0px' }}
              onClick={() => setShowErrors(true)}
              label={
                <Text style={{ textDecoration: 'underline' }}>
                  {`View all failed ${baseName}.`}
                </Text>
              }
            />
          </Box>
        }
      />
      {showErrors && (
        <Layer
          onClickOutside={() => setShowErrors(false)}
          onEsc={() => setShowErrors(false)}
          modal={true}
        >
          <ErrorsModal
            itemName={baseName}
            error={error}
            onClose={() => setShowErrors(false)}
          />
        </Layer>
      )}
    </>
  );
};
