// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import { useQueries } from 'react-query';

import auth from '../lib/auth';
import { get } from '../lib/rest';
import { Edit } from 'grommet-icons';
import { toDateTime } from '../lib/formatters';
import {
  makeRestUrl,
  SCOPE_PORTAL,
  SCOPE_HOSTER,
  URL_DASHBOARD,
  URL_MACHINESIZES,
} from '../routes/consts';
import { getDashboard } from './dashboard';
import {
  getDefaultQueriesResponse,
  renderLink,
  renderMutableLink,
} from './helper';

const getFromPool = (dashboard, name, key) => {
  const pools = dashboard?.machine_pool_summary?.machine_pools || [];
  const pool = pools.find(({ name: poolName }) => poolName === name);

  return pool?.[key] || '';
};

const columns = () => [
  {
    header: 'Name',
    isDefault: true,
    property: 'name',
    render: renderLink({
      base: URL_MACHINESIZES,
    }),
  },
  {
    header: 'Description',
    isDefault: true,
    property: 'description',
  },
  {
    header: 'ID',
    isDefault: true,
    property: 'id',
  },
  {
    header: 'Weight',
    isDefault: true,
    property: 'weight',
  },
  {
    header: 'Available',
    isDefault: true,
    property: 'unallocated',
  },
  {
    header: 'Allocated',
    isDefault: true,
    property: 'allocated',
  },
  {
    header: 'Others',
    isDefault: true,
    property: 'others',
  },
  {
    header: 'Created',
    property: 'created',
    render: ({ created }) => toDateTime(created),
  },
  {
    header: 'Modified',
    isDefault: true,
    property: 'modified',
    render: ({ modified }) => toDateTime(modified),
  },
  ...(auth.inScope(SCOPE_PORTAL)
    ? [
        {
          header: 'Published',
          isDefault: true,
          property: 'hoster_published',
          render: ({ hoster_id: id }) => (id === '*' ? 'yes' : 'no'),
        },
      ]
    : []),
  ...(auth.inScope(SCOPE_HOSTER)
    ? [
        {
          header: 'Edit',
          isDefault: true,
          property: 'edit',
          render: renderMutableLink({
            base: URL_MACHINESIZES,
            icon: <Edit color='brand' />,
            isEdit: true,
          }),
        },
      ]
    : []),
];

export const machineSizesMapper = (data) => ({
  ...data,
  immutable: !!data.auto_config?.synced_from_remote,
});

export const getMachineSizes = async () => {
  const url = makeRestUrl(URL_MACHINESIZES);
  const response = await get(url);

  return response.json();
};

export const useGetMachineSizes = ({ hosterId }) => {
  const queryResults = useQueries([
    {
      queryKey: URL_MACHINESIZES,
      select: (data) => data.map(machineSizesMapper),
      queryFn: getMachineSizes,
    },
    {
      queryKey: URL_DASHBOARD,
      queryFn: getDashboard(hosterId),
      enabled: !!hosterId,
    },
  ]);

  const isLoaded = queryResults.every(
    ({ error, isLoading }) => !isLoading && !error,
  );

  if (isLoaded) {
    const [machineSizesQuery, dashboardQuery] = queryResults;
    const dashboard = dashboardQuery?.data || {};

    return {
      columns: columns()
        // adding columnName and displayName for backwards compatibility
        .map((column) => ({
          ...column,
          columnName: column.property,
          displayName: column.header,
        })),
      data:
        machineSizesQuery.data?.map((machineSize) => ({
          ...machineSize,
          allocated: getFromPool(dashboard, machineSize.name, 'allocated'),
          other: getFromPool(dashboard, machineSize.name, 'other'),
          unallocated: getFromPool(dashboard, machineSize.name, 'unallocated'),
        })) ?? [],
      isLoading: false,
      refetch: () => {
        machineSizesQuery.refetch();
        dashboardQuery.refetch();
      },
      error: null,
    };
  }

  return getDefaultQueriesResponse(queryResults);
};
