// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  FormField,
  Paragraph,
  Select,
  TextInput,
  Text,
  ThemeContext,
  Button,
} from 'grommet';
import { useHistory } from 'react-router-dom';
import { Pause, Refresh, Resume, Search } from 'grommet-icons';

import {
  Alerts,
  StyledDataTable,
  TableSettings,
  View,
  ViewHeader,
} from '../../components';
import { MSG_FETCHING, MSG_FETCH_NO_DATA } from '../../lib/messages';
import { isEmpty, useInterval } from '../../utils';
import { GetFilterMessage } from '../../utils/getFilterMessage';
import { AlertsContext } from '../../utils/context/alertsContext';
import {
  useVisibleColumns,
  useDataColumns,
  useHeaderActions,
  useDataSearch,
  useRowProps,
  useDataSort,
} from '../../utils/hooks';
import IconButton from '../../components/generic/IconButton';
import { REFRESH_TIME } from '../../config';
import { useMembershipContext } from '../../utils/context/membershipContext';

export default function ListPage({
  actions = {},
  dataParam = {},
  dataProps = {},
  noClickRow,
  noSelect,
  parentId,
  path,
  sort: defaultSort,
  tableProps = {},
  title,
  useGetData,
  dynamicLevel,
}) {
  const theme = useContext(ThemeContext);
  const { addAlert, alerts } = useContext(AlertsContext);
  const { activeMembership, role } = useMembershipContext();
  const history = useHistory();
  const [interval, setInterval] = useState(null);
  const [newColumns, setNewColumns] = useState({});
  const { columns, data, isLoading, refetch } = useGetData({
    addAlert,
    parentId,
    param: dataParam,
    hosterId: activeMembership?.hoster_id,
    props: dataProps,
    scope: role?.scope,
  });
  const { visibleColumns, visibleColumnNames } = useVisibleColumns(
    path,
    newColumns,
    columns,
  );
  const { filteredData, search, setSearch } = useDataSearch(data);
  const { dataColumns, resetSelected, selected } = useDataColumns(
    visibleColumns,
    filteredData,
    noSelect,
  );
  const {
    handleDelete,
    handleImport,
    handlePublish,
    handleUnpublish,
    handleUpdate,
  } = useHeaderActions({
    actions: {
      ...actions,
      base: actions.base || path,
    },
    selected,
    refetch,
    resetSelected,
  });
  const [sort, setSort] = useState({
    ...defaultSort,
    external: true,
  });
  const { sortedData } = useDataSort(filteredData, sort);
  const rowProps = useRowProps(selected);

  useEffect(() => {
    if (actions.poll) {
      setInterval(REFRESH_TIME);
    }
  }, [actions]);

  useInterval(() => {
    refetch(true);
  }, interval);

  const handlePause = () => {
    if (interval) {
      setInterval(null);
    } else {
      setInterval(REFRESH_TIME);
    }
  };

  const handleRefresh = () => {
    refetch();
  };

  const handleSearch = ({ target: { value } }) => {
    setSearch(value);
  };

  const handleSettingChange = (updatedColumns) => setNewColumns(updatedColumns);

  const handleClickRow = !noClickRow
    ? ({ datum: { id } }) => history.push(`/s/${path}/${id}`)
    : undefined;

  const handleSort = ({ direction, property }) => {
    setSort({ direction, external: true, property });
  };

  const skeleton = { message: { start: 'Loading', end: '' } };

  return (
    <View>
      <ViewHeader
        createLabel={actions.allowCreate}
        onCreate={actions.allowCreateCb}
        onDelete={handleDelete}
        onImport={handleImport}
        onPublish={handlePublish}
        onUpdate={handleUpdate}
        onUnpublish={handleUnpublish}
        level={dynamicLevel}
      >
        {title}
      </ViewHeader>

      <Alerts alerts={alerts} />

      {!isEmpty(dataParam) ? (
        <Box pad={{ vertical: 'small' }} width='small'>
          <FormField label={dataParam.label}>
            <Select
              onChange={dataParam.onChange}
              options={dataParam.options}
              value={dataParam.value}
            />
          </FormField>
        </Box>
      ) : null}

      <Box
        style={{
          width: 'fit-content',
          maxWidth: 'max-content',
        }}
        skeleton={isLoading ? skeleton : undefined}
      >
        <Box pad={{ bottom: 'small' }} fill='horizontal'>
          <Box gap='xsmall' direction='row' justify='start'>
            <Box alignSelf='start' direction='row'>
              <TextInput
                width='medium'
                type='search'
                icon={<Search />}
                placeholder='Search'
                value={search}
                onChange={handleSearch}
              />
            </Box>
            <Box alignSelf='start' direction='row' gap='xsmall'>
              {!isEmpty(visibleColumnNames) && (
                <TableSettings
                  columnMetadata={columns}
                  onChange={handleSettingChange}
                  page={path}
                  visibleColNames={visibleColumnNames}
                />
              )}
              {actions.allowPause ? (
                <IconButton
                  icon={interval ? <Pause /> : <Resume />}
                  kind='toolbar'
                  onClick={handlePause}
                  tip={interval ? 'Pause polling' : 'Resume polling'}
                />
              ) : (
                <IconButton
                  icon={<Refresh />}
                  kind='toolbar'
                  onClick={handleRefresh}
                  tip='Refresh'
                />
              )}
            </Box>
          </Box>
        </Box>
        <Box alignSelf='start' margin={{ top: '6px' }}>
          <GetFilterMessage data={data} filteredData={filteredData} />
        </Box>
        <StyledDataTable
          background={{
            body: ['background-back', 'background-front'],
          }}
          columns={dataColumns}
          data={sortedData}
          onClickRow={handleClickRow}
          onSort={handleSort}
          primaryKey='id'
          rowProps={rowProps}
          sort={sort}
          sortable
          step={1000}
          style={{ width: 'fit-content' }}
          theme={theme}
          {...tableProps}
        />
      </Box>

      {!data?.length ? (
        <Box
          pad={{ horizontal: 'medium', vertical: 'large' }}
          skeleton={isLoading ? skeleton : undefined}
        >
          <Paragraph>{isLoading ? MSG_FETCHING : MSG_FETCH_NO_DATA}</Paragraph>
        </Box>
      ) : null}
    </View>
  );
}

ListPage.propTypes = {
  actions: PropTypes.object,
  dataParam: PropTypes.object,
  dataProps: PropTypes.object,
  noClickRow: PropTypes.bool,
  noSelect: PropTypes.bool,
  parentId: PropTypes.string,
  path: PropTypes.string,
  sort: PropTypes.object,
  tableProps: PropTypes.object,
  title: PropTypes.string,
  useGetData: PropTypes.func,
};
