// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React, { createContext, useContext, useState } from 'react';

export const NotificationInlineContext = createContext({});

export const NotificationInlineProvider = ({ children }) => {
  const [inlineNotification, setInlineNotification] = useState(null);
  const [notificationId, setNotificationId] = useState(null);

  const handleClose = () => {
    setNotificationId(null);
    setInlineNotification(null);
  };

  return (
    <NotificationInlineContext.Provider
      value={{
        handleClose,
        inlineNotification,
        notificationId,
        setInlineNotification: (id, node) => {
          setNotificationId(id);
          setInlineNotification(node);
        },
      }}
    >
      {children}
    </NotificationInlineContext.Provider>
  );
};

export const useNotificationInlineContext = () => {
  const context = useContext(NotificationInlineContext);

  if (!context) {
    throw new Error(
      'NotificationInlineContext must be used within a NotificationInlineProvider',
    );
  }

  return context;
};
