// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import { useQuery } from 'react-query';

import { toDateTime } from '../lib/formatters';
import { get } from '../lib/rest';
import auth from '../lib/auth';
import {
  makeRestUrl,
  makeSecItemUrl,
  SCOPE_PORTAL,
  SCOPE_HOSTER,
  setId,
  URL_HOSTER_SERVICES,
  URL_HOSTER_SERVICE_CATALOG,
  URL_SERVICES,
} from '../routes/consts';
import { isEmpty } from '../utils';
import CoreInfo from '../components/Griddle/CoreInfo';
import { OsIcon } from '../components/icons';
import { Edit } from 'grommet-icons';
import { renderMutableLink } from './helper';

const columns = (itemUrl) => [
  {
    header: 'Name',
    isDefault: true,
    property: 'name',
    render: ({
      description,
      id,
      name,
      svc_flavor: flavor,
      svc_ver: detail,
    }) => (
      <CoreInfo
        url={setId(makeSecItemUrl(itemUrl), id)}
        name={name}
        icon={<OsIcon flavor={flavor} />}
        detail={detail}
        description={description}
      />
    ),
  },
  {
    header: 'ID',
    property: 'id',
  },
  {
    header: 'Description',
    property: 'property',
  },
  {
    header: 'Approach',
    property: 'approach',
  },
  {
    header: 'Svc type',
    isDefault: true,
    property: 'type',
  },
  {
    header: 'Origin',
    isDefault: true,
    property: 'origin',
  },
  {
    header: 'Svc flavor',
    isDefault: true,
    property: 'svc_flavor',
  },
  {
    header: 'Svc version',
    isDefault: true,
    property: 'svc_ver',
  },
  {
    header: 'Hoster visible',
    isDefault: true,
    property: 'hoster_use',
    render: ({ hoster_use: use }) => (use ? 'yes' : 'no'),
  },
  {
    header: 'Hoster ID',
    property: 'hoster_id',
  },
  {
    header: 'Project visible',
    isDefault: true,
    property: 'project_use',
    render: ({ project_use: use }) => (use ? 'yes' : 'no'),
  },
  {
    header: 'Svc timeout',
    property: 'timeout',
  },
  {
    header: 'Created',
    property: 'created',
    render: ({ created }) => toDateTime(created),
  },
  {
    header: 'Modified',
    property: 'modified',
    render: ({ modified }) => toDateTime(modified),
  },
  ...(auth.inScope(SCOPE_PORTAL)
    ? [
        {
          header: 'Published',
          isDefault: true,
          property: 'hoster_published',
          render: ({ hoster_id: id }) => (id === '*' ? 'yes' : 'no'),
        },
      ]
    : []),
  ...(auth.inScope(SCOPE_HOSTER) && itemUrl === URL_HOSTER_SERVICES
    ? [
        {
          header: 'Edit',
          isDefault: true,
          property: 'edit',
          render: renderMutableLink({
            base: URL_HOSTER_SERVICES,
            icon: <Edit color='brand' />,
            isEdit: true,
          }),
        },
      ]
    : []),
];

export const hosterServicesMapper = (data) => ({
  ...data,
  immutable: !!data.auto_config?.synced_from_remote,
});

export const getServices = async () => {
  const response = await get(makeRestUrl(URL_SERVICES));

  return response.json();
};

export const useGetServices = () => {
  const queryResult = useQuery(URL_SERVICES, getServices);

  return {
    ...queryResult,
    columns: columns(URL_SERVICES).map((column) => ({
      ...column,
      columnName: column.property,
      displayName: column.header,
    })),
    data:
      queryResult.data?.filter(
        ({ hoster_id: hosterId }) => hosterId === '*' || isEmpty(hosterId),
      ) || [],
  };
};

export const useGetHosterServices = ({ props }) => {
  const queryResult = useQuery({
    queryKey: URL_HOSTER_SERVICES,
    select: (data) => data.map(hosterServicesMapper),
    queryFn: getServices,
  });

  return {
    ...queryResult,
    columns: columns(URL_HOSTER_SERVICES).map((column) => ({
      ...column,
      columnName: column.property,
      displayName: column.header,
    })),
    data:
      queryResult.data?.filter(
        ({ hoster_id: id, type }) =>
          id !== '*' && (!props.types || props.types.includes(type)),
      ) || [],
  };
};

export const useGetHosterServiceCatalogs = () => {
  const queryResult = useQuery({
    queryKey: URL_HOSTER_SERVICES,
    select: (data) => data.map(hosterServicesMapper),
    queryFn: getServices,
  });

  return {
    ...queryResult,
    columns: columns(URL_HOSTER_SERVICE_CATALOG).map((column) => ({
      ...column,
      columnName: column.property,
      displayName: column.header,
    })),
  };
};
