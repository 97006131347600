// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import get from 'lodash/get';
import * as mb from '../../containers/generic/MetaBuilder';
import * as c from '../../routes/consts';
import MetaBuilder from '../../containers/generic/MetaBuilder';
import ItemViewContainer from '../../containers/generic/ItemViewContainer';
import CreateViewContainer from '../../containers/generic/CreateViewContainer';
import EditViewContainer from '../../containers/generic/EditViewContainer';
import { TagsDisplay } from '../../components/tags/TagsDisplay';
import { TagsEditDisplay } from '../../components/tags/TagsEditDisplay';
import auth from '../../lib/auth';
import debugLogger from '../../lib/debug';
import * as log from '../../lib/debug';
import { isEmpty } from '../../utils';
import { ValidationResult } from '../../containers/generic/MetaBuilder';
import { useGetVolumeFlavorMetaAlletra } from '../../utils/hooks';
import { MIN_GB_ALLETRA_9K } from '../../lib/consts';
import { LABEL_VOLUMES } from '../../components/HybridNav/consts.js';
import { volumeMapper } from '../../services/volumes.js';

const debug = debugLogger('VolumeView::index::', log.LOG_LEVEL_DEBUG);

const BASE_URL = c.URL_VOLUMES;

const enabledOrDisabled = (isTrue) => (isTrue ? 'Enabled' : 'Disabled');

const getUniqueFlavors = (inventory, locationId) => {
  const flavors = inventory
    .filter(({ location_id }) => location_id === locationId)
    .flatMap(({ flavor_infos }) => flavor_infos);

  const uniqueFlavors = Array.from(new Set(flavors.map(({ id }) => id))).map(
    (uniqueId) => flavors.find(({ id }) => id === uniqueId),
  );

  return uniqueFlavors;
};

const getStoragePoolOptions = (flavorId, data) => {
  const { inventory, storage_pools } = data;

  const storagePoolIds = inventory
    .filter(({ flavor_infos }) =>
      flavor_infos.find(({ id }) => id === flavorId),
    )
    .map(({ storage_pool_id }) => storage_pool_id);

  const storagePoolOptions = storagePoolIds.map((storagePoolId) =>
    storage_pools.find(({ id }) => id === storagePoolId),
  );

  return storagePoolOptions;
};

const setStoragePools = (flavorId, data, dsOptions) => {
  const storagePoolsOptions = getStoragePoolOptions(flavorId, data);
  dsOptions.SetData([{ id: 'auto', name: 'Auto' }, ...storagePoolsOptions]);
};

const getVolumeCollectionOptions = (
  inventory,
  collections,
  currentLocation,
  selectedStoragePoolId,
) => {
  if (!collections || !currentLocation) {
    return [{ id: 'none', name: 'None' }];
  }

  const filteredInventory = inventory.filter(
    (selectedInventory) =>
      selectedInventory.location_id === currentLocation &&
      selectedInventory.storage_pool_id === selectedStoragePoolId,
  );

  const volumeCollectionIds = filteredInventory.flatMap(
    (inv) => inv.volume_collection_ids,
  );

  const matchingCollections = collections.filter((collection) =>
    volumeCollectionIds.includes(collection.id),
  );

  const volumeCollectionOptions = matchingCollections.map((collection) => ({
    id: collection.id,
    name: collection.name,
  }));

  if (volumeCollectionOptions.length > 0) {
    return [{ id: 'none', name: 'None' }, ...volumeCollectionOptions];
  }

  return volumeCollectionOptions;
};

function metaCreate(props, volumeFlavorMetaAlletra) {
  const m = new MetaBuilder(props);

  const dsVolumeFlavors = m.newStaticDataSource([]);
  const dsStoragePools = m.newStaticDataSource([]);
  const dsVolumeCollections = m.newStaticDataSource([]);

  const dsAvail = m
    .newDataSource(c.URL_AVAIL_STORAGE_RESOURCES)
    .OnLoad(({ data }) => {
      const uniqueFlavors = getUniqueFlavors(
        data?.inventory,
        data?.locations?.[0].id,
      );

      dsVolumeFlavors.SetData([
        { id: 'select_volume_flavor', name: 'Select volume flavor' },
        ...uniqueFlavors,
      ]);
      dsStoragePools.SetData([{ id: 'auto', name: 'Auto' }]);
      dsVolumeCollections.SetData([{ id: 'none', name: 'None' }]);
    });

  const inHosterScope = auth.inScope(c.SCOPE_HOSTER);
  const dsProjects = inHosterScope ? m.newDataSource(c.URL_PROJECTS) : [];

  debug.debug('metaCreate: inHosterScope:', inHosterScope);

  // If hoster, select project that will own the host (for on-behalf-of)
  if (inHosterScope) {
    m.addField('_temp.project', 'On behalf of project')
      .Input()
      .DropDown()
      .DataXform(dsProjects, (json) => {
        // using id of 'self' because a value of '' causes loop
        const tms = [{ id: 'self', name: 'Self' }];
        return tms.concat(json.map((t) => ({ id: t.id, name: t.name })));
      })
      .MaxWidth(400)
      .OnChange((val) => {
        let value = val;

        if (value !== undefined) {
          if (value === 'self') {
            dsAvail.DeleteQuery('project');
            value = '';
          } else {
            dsAvail.AddQuery('project', value);
          }

          dsAvail.Fetch();
          m.view.setFormValue('project_id', value);
        }
      });
  }

  m.addColumn('name', 'Name').Input().Required().MaxWidth(400);

  m.addColumn('description', 'Description').Input().MaxWidth(400);

  m.addColumn('podID', 'Location')
    .Input()
    .Required()
    .DropDown()
    .DataXform(dsAvail, (json) =>
      get(json, 'locations', []).map(
        ({ country, data_center, id, region }) => ({
          id,
          name: `${country}: ${region}: ${data_center}`,
        }),
      ),
    )
    .OnChange((val) => {
      const { inventory, locations } = dsAvail.Data();
      const location = locations.find(({ id }) => id === val);
      m.view.setFormValue(
        'location.country',
        get(location, 'country', 'no country'),
      );
      m.view.setFormValue(
        'location.region',
        get(location, 'region', 'no region'),
      );
      m.view.setFormValue(
        'location.data_center',
        get(location, 'data_center', 'no dc'),
      );

      const uniqueFlavors = getUniqueFlavors(inventory, val);

      dsVolumeFlavors.SetData(uniqueFlavors);
    })
    .MaxWidth(400);

  m.addColumn('volumeFlavorID', 'Volume flavor')
    .Input()
    .Required()
    .DropDown('Select volume flavor')
    .DataXform(dsVolumeFlavors, (json) =>
      json.map(({ id, name }) => ({
        id,
        name,
      })),
    )
    .MaxWidth(400)
    .OnInit((options) => {
      const [selectedFlavor] = options.data;
      setStoragePools(selectedFlavor?.id, dsAvail.Data(), dsStoragePools);
    })
    .OnChange((val) => {
      setStoragePools(val, dsAvail.Data(), dsStoragePools);
    });

  m.addColumn('storage_pool_id', 'Storage pool')
    .Input()
    .Required()
    .DropDown()
    .DataXform(dsStoragePools, (json) =>
      json.map(({ id, name }) => ({
        id,
        name,
      })),
    )
    .Visible(
      () =>
        !isEmpty(m.view.formDefaultTo('volumeFlavorID')) &&
        m.view.formDefaultTo('volumeFlavorID') !== 'select_volume_flavor',
    )
    .MaxWidth(400)
    .OnChange((val) => {
      const { inventory, locations, volume_collections } = dsAvail.Data();
      const selectedLocationId = locations?.[0]?.id;

      const volumeCollections = getVolumeCollectionOptions(
        inventory,
        volume_collections,
        selectedLocationId,
        val,
      );

      dsVolumeCollections.SetData(volumeCollections);
    });

  m.addColumn('volumeCollectionID', 'Volume collection')
    .Input()
    .DropDown()
    .DataXform(dsVolumeCollections, (json) =>
      json.map(({ id, name }) => ({
        id,
        name,
      })),
    )
    .MaxWidth(400)
    .Visible(
      () =>
        !isEmpty(
          m.view.formDefaultTo('storage_pool_id') &&
            m.view.formDefaultTo('storage_pool_id') !== 'auto' &&
            m.view.formDefaultTo('volumeFlavorID') !== 'select_volume_flavor' &&
            dsVolumeCollections.Data().length > 0,
        ),
    )
    .OnChange(() => {});

  m.addColumn('capacity', 'Size (GB)')
    .Input()
    .Number()
    .Required()
    .MaxWidth(400)
    .Mask([{ regexp: /^\d+$/ }])
    .CustomValidator((val) => {
      const a9kFlavors = Object.keys(volumeFlavorMetaAlletra.result);

      const selectedStoragePoolsIds = dsStoragePools
        .Data()
        ?.map((pool) => pool?.id)
        .filter((id) => id !== 'auto');

      const storagePoolsWithA9k = dsAvail
        .Data()
        ?.inventory?.filter(({ flavor_infos }) => {
          return flavor_infos?.some(({ id }) => {
            if (id) return a9kFlavors.includes(id);
            return false;
          });
        });

      const a9kIsSelected = storagePoolsWithA9k?.some(({ storage_pool_id }) =>
        selectedStoragePoolsIds?.includes(storage_pool_id),
      );

      if (a9kIsSelected && val < MIN_GB_ALLETRA_9K) {
        return new ValidationResult(
          false,
          `Value must be greater than or equal to ${MIN_GB_ALLETRA_9K}GB`,
        );
      }

      return new ValidationResult(true, '');
    });

  m.addField('shareable', 'Shareable').Input().CheckBox(false).MaxWidth(400);

  const tagsComponent =
    props.mode === 'create' || props.mode === 'edit'
      ? TagsEditDisplay
      : TagsDisplay;

  m.addColumn('labels', 'Tags').Input().Custom(tagsComponent);

  return m;
}

function metaEdit(props) {
  const m = new MetaBuilder(props);
  const id = m.view.prop('itemId', false);
  m.newDataSource(c.URL_VOLUMES)
    .Item(id)
    .OnLoad(({ data }) => {
      const form = {
        ...data,
        capacity: 0,
      };
      m.view.initForm(form);
    });
  const tagsComponent =
    props.mode === 'create' || props.mode === 'edit'
      ? TagsEditDisplay
      : TagsDisplay;

  m.addColumn('name', 'Name').Input().MaxWidth(400).ReadOnly();
  m.addColumn('labels', 'Tags').Input().Custom(tagsComponent);

  return m;
}

function metaView(props) {
  const m = new MetaBuilder(props);
  const id = m.view.prop('itemId', false);
  const dsVolumeFlavor = m.newDataSource(c.URL_VOLUME_FLAVOR);
  const ds = m
    .newDataSource(c.URL_VOLUMES)
    .Item(id)
    .useMapper(volumeMapper)
    .OnLoad(({ data }) => {
      // convert from KiB to GB
      const form = {
        ...data,
        capacity: Math.round(data.capacity / 976562.5),
        capacity_used: Math.round(data.capacity_used / 976562.5),
      };
      m.view.initForm(form);
    });

  const inHosterScope = auth.inScope(c.SCOPE_HOSTER);

  m.addColumn('name', 'Name');

  m.addColumn('description', 'Description');

  m.addColumn('id', 'ID');

  m.addColumn('capacity', 'Size (GB)');

  m.addColumn('capacity_used', 'Capacity used (GB)').Visible(
    () => m.view.formDefaultTo('unmanaged_volume') === true,
  );

  m.addColumn('volumeFlavorID', 'Flavor Name')
    .DropDown()
    .ReadOnly()
    .DataXform(dsVolumeFlavor, (json) =>
      json.map((t) => ({ id: t.id, name: t.name })),
    )
    .ShowLink();

  m.addColumn('capacityPoolID', 'Pool ID');

  m.addColumn('volumeGroupID', 'Group ID');

  m.addColumn('volumeCollectionID', 'Volume collection');

  m.addColumn('active_site', 'Active site').Visible(
    () => m.view.formDefaultTo('unmanaged_volume') === true,
  );

  m.addColumn('created_site', 'Created site').Visible(
    () => m.view.formDefaultTo('unmanaged_volume') === true,
  );

  m.addColumn('replication_enabled', 'Replication').FieldXform(
    enabledOrDisabled,
  );

  m.addColumn('wwn', 'WWN').Visible(
    () =>
      m.view.formDefaultTo('unmanaged_volume') === true &&
      !!m.view.formDefaultTo('wwn'),
  );

  // Volume State section
  m.addSection('Volume state').MaxWidth(mb.LARGE);

  m.addColumn('state', 'State');

  m.addColumn('status', 'Status');

  m.addColumn('shareable', 'Shareable').FieldXform((shareable) =>
    shareable ? 'yes' : 'no',
  );

  m.addColumn('export_cnt', 'Export count');

  m.addColumn('monitored', 'Monitored').FieldXform((monitored) =>
    monitored ? 'yes' : 'no',
  );

  const eventTable = m
    .addInputTable('events', 'Events')
    .ReadOnly()
    .DataXform(ds, ({ events }) => events || []);

  eventTable
    .addField('type', 'Event type')
    .CellXform((rowData) => rowData.type);

  eventTable
    .addField('user_name', 'User name')
    .CellXform((rowData) => rowData.user_name);

  eventTable.addField('time', 'Time').CellXform((rowData) => rowData.time);

  // Location Section
  m.addSection('Location').MaxWidth(mb.LARGE);

  m.addColumn('location.country', 'Country');

  m.addColumn('location.region', 'Region');

  m.addColumn('location.data_center', 'Data center');

  if (inHosterScope) {
    m.addColumn('podID', 'Pod ID').MakeLink(c.URL_PODS);
  }

  m.addSection('Tags').MaxWidth(mb.LARGE);
  const tagsComponent =
    props.mode === 'create' || props.mode === 'edit'
      ? TagsEditDisplay
      : TagsDisplay;

  m.addColumn('labels', 'Tags').Input().Custom(tagsComponent);

  return m;
}

const settings = {
  // used to filter create/trash icons from the view; using
  // the defined roledef permissions mappings to this auth URL entry
  authUrl: BASE_URL,
  // homepage for this list view (e.g. /s/hosters/:pid/) is where
  // this view is located; for return from create / item views
  homeUrl: c.makeSecUrl(BASE_URL),
  homeLabel: LABEL_VOLUMES,
  // base url to be used for creating all associated URLS for
  // this reasource, e.g. pageItem, pageCreate, restUrl, restItemUrl
  baseUrl: BASE_URL,
  meta: (props) => metaView(props),
};

export const VolumeItemView = ItemViewContainer({
  ...settings,
  meta: (props) => metaView(props),
  allowEdit: ({ immutable }) => !immutable,
  title: 'Volume',
});

export const VolumeCreateView = (props) => {
  const volumeFlavorMetaAlletra = useGetVolumeFlavorMetaAlletra();

  if (volumeFlavorMetaAlletra.isLoading) return null;

  const Component = CreateViewContainer({
    ...settings,
    meta: (props) => metaCreate(props, volumeFlavorMetaAlletra),
    title: 'Create volume',
  });

  return <Component {...props} />;
};

export const VolumeEditView = EditViewContainer({
  ...settings,
  meta: (props) => metaEdit(props),
  title: 'Edit Volume',
});

export { default as VolumeListView } from './VolumeListView';
