// (C) Copyright 2024 Hewlett Packard Enterprise Development LP

import React, { useContext, useMemo, useState } from 'react';
import { Box, Button, CheckBox, Text } from 'grommet';
import { Modal } from '../../components';
import { importExternalVolumes } from '../../services/volumes';
import { CircleAlert } from 'grommet-icons';
import { AlertsContext } from '../../utils/context/alertsContext';
import { getHosters } from '../../services/hosters';
import auth from '../../lib/auth';

export const useCanImportVolume = () => {
  const [canImport, setCanImport] = useState(false);
  const isHosterOwner = auth.activeRoleDef?.id === 'hoster_owner';
  const isHosterAdmin = auth.activeRoleDef?.id === 'hoster_admin';

  if (isHosterOwner || isHosterAdmin) {
    getHosters()
      .then((hosters = [{}]) => {
        const [{ enable_ext_vol_import }] = hosters;

        if (enable_ext_vol_import) {
          setCanImport(true);
        }
      })
      .catch((err) => {});
  }

  return canImport;
};

export function ImportExternalVolumeButton() {
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [errors, setErrors] = useState('');
  const { addAlert } = useContext(AlertsContext);

  const handleModalClose = () => {
    setOpen(false);
    setChecked(false);
    setErrors('');
  };

  const handleConfirm = () => {
    importExternalVolumes()
      .then(() => {
        handleModalClose();
        addAlert('Importing external volumes started');
      })
      .catch(async (err) => {
        if (err.response) {
          const errorMessage = await new Response(err.response.body).text();
          setErrors(errorMessage);
        } else {
          handleModalClose();
          addAlert('Importing external volumes started');
        }
      });
  };

  return (
    <>
      <Button
        label={'Import external volumes'}
        secondary
        onClick={() => setOpen(true)}
      />
      <Modal show={open} onHide={handleModalClose} size='medium'>
        <Modal.Header onDismiss={handleModalClose}>
          <Modal.Title>Import external volumes</Modal.Title>
        </Modal.Header>
        <Box gap='medium' pad={{ horizontal: 'medium' }}>
          <Box>
            <Text>
              By importing external volumes, BMaaS/GreenLake Metal will initiate
              the import process. Once the external volumes are imported, they
              cannot be modified by BMaaS/GreenLake Metal users.
            </Text>
          </Box>
          <Box
            border='all'
            round='xsmall'
            pad='small'
            direction='row'
            justify='between'
            align='start'
            gap='small'
          >
            <CheckBox
              label={
                'I confirm that I have read and understand the consequences of this action.'
              }
              checked={checked}
              onChange={({ target }) => setChecked(target.checked)}
            />
          </Box>
          <Box>
            <Text>Import all external volumes?</Text>
          </Box>
          {errors ? (
            <Box
              align='center'
              background='validation-critical'
              direction='row'
              gap='xsmall'
              pad='small'
              round='xsmall'
            >
              <CircleAlert size='small' />
              <Text style={{ lineHeight: '25px' }}>{errors}</Text>
            </Box>
          ) : null}
        </Box>
        <Modal.Footer>
          <Box
            direction='row'
            fill={true}
            gap='small'
            justify='end'
            pad={{ vertical: 'small' }}
          >
            <Button
              label='Cancel'
              onClick={handleModalClose}
              secondary={true}
            />
            <Button
              disabled={!checked}
              label='Yes, import external volumes'
              onClick={handleConfirm}
              primary={true}
            />
          </Box>
        </Modal.Footer>
      </Modal>
    </>
  );
}
