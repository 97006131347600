// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import defaultTo from 'lodash/defaultTo';
import { toDateTime } from '../../lib/formatters';
import * as c from '../../routes/consts.js';
import * as mb from '../../containers/generic/MetaBuilder';
import MetaBuilder from '../../containers/generic/MetaBuilder';
import ItemViewContainer from '../../containers/generic/ItemViewContainer';
import rest from '../../lib/rest';
import { useMembershipContext } from '../../utils/context/membershipContext';
import { useMemo } from 'react';
import { LABEL_ALERTS } from '../../components/HybridNav/consts.js';

const EXTERNAL_NAME = c.URL_ALERTS;

const sourceTypeToUrlMap = {
  machines: c.URL_MACHINES,
  hosts: c.URL_HOSTS,
  arrays: c.URL_ARRAYS,
  switches: c.URL_SWITCHES,
  rackcontrollers: c.URL_RACKCONTROLLERS,
  ports: c.URL_SWITCHPORTS,
  storagesystems: c.URL_STORAGE_SYSTEMS,
};

const sourceTypeToUrl = (sourceType) => sourceTypeToUrlMap[sourceType] || '';

export const settings = {
  authUrl: EXTERNAL_NAME, // used to filter create/trash icons from the view; using the defined roledef permissions mappings to this auth URL entry
  homeUrl: c.makeSecUrl(EXTERNAL_NAME), // homepage for this list view (e.g. /s/hosters/:pid/) is where this view is located; for return from create / item views
  homeLabel: LABEL_ALERTS,
  baseUrl: EXTERNAL_NAME, // base url to be used for creating all associated URLS for this reasource, e.g. pageItem, pageCreate, restUrl, restItemUrl
  stateKey: EXTERNAL_NAME,
};

function metaEdit(props) {
  const m = new MetaBuilder(props);

  const id = m.view.prop('itemId', false);
  const ds = m
    .newDataSource(c.URL_ALERTS)
    .Item(id)
    .OnLoad((json) => {
      m.view.initForm(json.data);
    });

  m.addSection('1').NoChrome().MaxWidth(m.LARGE);

  // m.addColumn('name', 'Name').Input().ReadOnly()
  m.addColumn('id', 'ID').Input().ReadOnly();
  m.addColumn('description.short', 'Summary').Input().ReadOnly();
  m.addColumn('description.long', 'Details').Input().ReadOnly();
  // m.addColumn('source_type', 'Source Type').Input().ReadOnly()
  m.addColumn('source_id', 'Source')
    .Input()
    .ReadOnly()
    .MakeLink(sourceTypeToUrl);
  m.addColumn('created', 'Created')
    .FieldXform((created) => (created ? toDateTime(created) : '--'))
    .Input()
    .ReadOnly();
  m.addColumn('modified', 'Modified')
    .FieldXform((modified) => (modified ? toDateTime(modified) : '--'))
    .Input()
    .ReadOnly();
  m.addColumn('created_by', 'Created by').Input().ReadOnly();
  m.addColumn('created_by_id', 'Created by ID').Input().ReadOnly();

  m.addSection('Status').MaxWidth(mb.XLARGE);

  m.addColumn('severity', 'Severity').Input().ReadOnly();
  m.addColumn('priority', 'Priority').Input().ReadOnly();
  m.addColumn('state', 'State').Input().ReadOnly();

  m.addSection('Notes').MaxWidth(mb.XLARGE);

  const notesTable = m
    .addInputTable('notes', 'Notes')
    .DataXform(ds, ({ notes }) => notes || [])
    .Editable((rowData) => (rowData ? !rowData.read_only : true))
    .Deletable((rowData) => (rowData ? !rowData.read_only : true));

  // Force the notes table add/edit/delete icons to appear in the item view
  notesTable.isReadOnly = props?.viewOnlyRole || false;

  notesTable.AddDialogMeta(metaNotesAdd);
  notesTable.OnAdd((data) => {
    const note = {
      text: data.text,
      // Note the id/author/author_id/created are assigned by the back-end based
      // on who is making the REST call.
    };
    // Post to database
    const notesUrl = c.setParentId(
      c.makeRestUrl(c.makeChildUrl(c.URL_ALERTS, c.URL_NOTES)),
      id,
    );
    rest
      .post(notesUrl, [note])
      .then((res) => {
        if (!res.ok) {
          props.view.addAlert(
            `Unable to add the note: ${res.statusText}`,
            'danger',
          );
        } else {
          props.view.addAlert('Successfully added the note');
          ds.Fetch(); // update the item view
        }
      })
      .catch((err) =>
        rest.errorInfo(err, (errorInfo) => {
          props.view.addAlert(
            `Unable to add the note: ${errorInfo.text}`,
            'danger',
          );
        }),
      );
  });

  notesTable.EditDialogMeta(metaNotesAdd);
  notesTable.OnEdit((nextData) => {
    if (!nextData.read_only) {
      const noteUrl = c.setParentId(
        c.makeRestUrl(c.makeChildUrl(c.URL_ALERTS, c.URL_NOTES)),
        id,
      );
      rest
        .put(noteUrl, nextData)
        .then((res) => {
          if (!res.ok) {
            props.view.addAlert(
              `Unable to change the note: ${res.statusText}`,
              'danger',
            );
          } else {
            props.view.addAlert('Successfully changed the note');
            ds.Fetch(); // update the item view
          }
        })
        .catch((err) =>
          rest.errorInfo(err, (errorInfo) => {
            props.view.addAlert(
              `Unable to change the note: ${errorInfo.text}`,
              'danger',
            );
          }),
        );
    } else {
      props.view.addAlert(
        'Unable to change the note: The note is read only',
        'danger',
      );
    }
  });

  notesTable.OnDelete((data) => {
    if (!data.read_only) {
      const noteUrl = c.setId(
        c.setParentId(
          c.makeRestItemUrl(c.makeChildUrl(c.URL_ALERTS, c.URL_NOTES)),
          id,
        ),
        data.id,
      );
      rest
        .del(noteUrl)
        .then((res) => {
          if (!res.ok) {
            props.view.addAlert(
              `Unable to delete the note: ${res.statusText}`,
              'danger',
            );
          } else {
            props.view.addAlert('Successfully deleted the note');
            ds.Fetch(); // update the item view
          }
        })
        .catch((err) =>
          rest.errorInfo(err, (errorInfo) => {
            props.view.addAlert(
              `Unable to delete the note: ${errorInfo.text}`,
              'danger',
            );
          }),
        );
    } else {
      props.view.addAlert(
        'Unable to delete the note: The note is read only',
        'danger',
      );
    }
  });

  notesTable.addField('text', 'Note').CellXform(({ text }) => text);

  notesTable
    .addField('created', 'Created')
    .CellXform(({ created }) => toDateTime(created));

  notesTable.addField('author', 'Author').CellXform(({ author }) => author);
  return m;
}

function metaNotesAdd(props) {
  const m = new MetaBuilder(props);

  m.formDefaults((form) => {
    form.notes = defaultTo(form.notes, []);
  });

  m.addSection('1').NoChrome();

  m.addColumn('text', 'Note').Input().MaxWidth(800).Required();

  return m;
}

export { default as AlertListView } from './AlertListView';

export const AlertItemView = (props) => {
  const { role } = useMembershipContext();

  const viewOnlyRole = useMemo(() => role?.id === 'hoster_viewer', [role]);

  const AlertItemViewContainer = ItemViewContainer({
    ...settings,
    allowEdit: false,
    meta: (propsMeta) => metaEdit({ ...propsMeta, viewOnlyRole }),
    title: 'Alert',
  });

  return <AlertItemViewContainer {...props} />;
};
