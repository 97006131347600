// (C) Copyright 2020-2024 Hewlett Packard Enterprise Development LP

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContext, Box, Text } from 'grommet';
import FormLabel from './FormLabel';

const FormReadOnlyField = ({ label, children, inputStyle = { style: {} } }) => {
  const size = useContext(ResponsiveContext);

  if (Object.keys(inputStyle.style)?.length > 0) {
    // Note: The extra style props here are used to ensure this component will render the same as its counterpart form inputs which are wrapped in the same grid.
    return (
      <>
        <Box
          style={{
            display: 'grid',
            boxSizing: 'border-box',
            width: '100%',
            gridTemplateColumns: 'auto 1fr',
            gap: '12px 48px',
            ...inputStyle.style,
          }}
        >
          <Text>{label}</Text>
          {children}
        </Box>
        <Box />
      </>
    );
  }

  // In narrow mode display the label above with the same style as FormField
  // Otherwise put the label in the left column and use the window width
  if (size === 'small')
    return (
      <Box style={{ gridColumn: '1 / span 2' }} pad={{ top: 'small' }}>
        <Text size='xsmall'>{label}</Text>
        {children}
      </Box>
    );

  return (
    <>
      <FormLabel>{label}</FormLabel>
      {children}
    </>
  );
};

FormReadOnlyField.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
  inputStyle: PropTypes.object,
};
export default FormReadOnlyField;
