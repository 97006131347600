// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React from 'react';

import ListPageWithNav from '../../containers/generic/ListPageWithNav';
import { URL_VOLUMES } from '../../routes/consts';
import { useGetVolumes } from '../../services/volumes';
import { NAV_STORAGE } from '../../components/HybridNav/consts';
import {
  ImportExternalVolumeButton,
  useCanImportVolume,
} from './ImportExternalVolumeButton';

const VolumeListView = () => {
  const canImportVolume = useCanImportVolume();
  return (
    <ListPageWithNav
      navSection={NAV_STORAGE}
      actions={{
        allowCreate: 'Create volume',
        extra: canImportVolume ? <ImportExternalVolumeButton /> : null,
        poll: true,
      }}
      path={URL_VOLUMES}
      sort={{
        property: 'name',
        direction: 'asc',
      }}
      title='Volumes'
      useGetData={useGetVolumes}
    />
  );
};

export default VolumeListView;
