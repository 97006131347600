// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import { SelectCard } from './SelectCard';

export const Location = ({
  data_center: dataCenter,
  country,
  disabled,
  onClick,
  selected,
  region,
}) => (
  <SelectCard
    disabled={disabled}
    footer={`${region}, ${country}`}
    onClick={onClick}
    selected={selected}
    title={dataCenter}
  />
);
