// (C) Copyright 2023-2024 Hewlett Packard Enterprise Development LP

import React, { useEffect } from 'react';
import { Box } from 'grommet';
import { useNotificationInlineContext } from '../../utils/context/notificationInlineContext';

export const InlineNotification = ({ id = null, ...props }) => {
  const { handleClose, inlineNotification, notificationId } =
    useNotificationInlineContext();

  useEffect(() => {
    // Close the notification if the id is not the one expected
    if (notificationId && notificationId !== id) {
      handleClose();
    }
  }, [handleClose, notificationId, id]);

  return <Box {...props}>{inlineNotification}</Box>;
};
