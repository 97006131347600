// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import { Box, Text } from 'grommet';
import { Checkmark } from 'grommet-icons';

export const SelectCard = ({
  children,
  disabled,
  footer,
  onClick,
  selected,
  title,
  ...rest
}) => {
  const background = disabled ? 'disabled' : 'background';
  const textColor = disabled ? 'text-weak' : 'text';
  const strong = disabled ? 'text-weak' : 'text-strong';

  return (
    <Box margin='small' background='background' flex round='xsmall'>
      <Box
        hoverIndicator={{ color: 'background-contrast' }}
        onClick={disabled ? undefined : onClick}
        border={{ size: selected ? 'small' : 'xsmall' }}
        round='xsmall'
        pad={{
          horizontal: selected ? '11px' : 'small',
          vertical: selected ? '5px' : 'xsmall',
        }}
        justify='between'
        background={background}
        fill='vertical'
      >
        <Box {...rest}>
          <Text weight='bold' color={strong} size='small' truncate>
            {title}
          </Text>
          {children}
        </Box>
        <Box height='24px' fill='horizontal' direction='row' justify='between'>
          <Text size='xsmall' color={textColor}>
            {footer}
          </Text>
          {selected && <Checkmark color='status-ok' />}
        </Box>
      </Box>
    </Box>
  );
};
