// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import Root from './containers/Root';
import { ThemeMode } from './components';
import { AlertsProvider } from './utils/context/alertsContext';
import './styles.css';
import { NotificationInlineProvider } from './utils/context/notificationInlineContext';

const queryClient = new QueryClient();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <AlertsProvider>
      <NotificationInlineProvider>
        <ThemeMode>
          <Router>
            <Root />
          </Router>
        </ThemeMode>
      </NotificationInlineProvider>
    </AlertsProvider>
  </QueryClientProvider>,
  document.getElementById('root'),
);
