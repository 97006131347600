// (C) Copyright 2022-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import { useQueries } from 'react-query';

import auth from '../lib/auth';
import { get } from '../lib/rest';
import {
  makeRestUrl,
  URL_MACHINES,
  URL_MACHINESIZES,
  URL_MACHINETYPES,
  URL_PODS,
  URL_RACKS,
} from '../routes/consts';
import {
  getDefaultQueriesResponse,
  MachineActions,
  renderLink,
  Status,
} from './helper';
import HealthStatus from '../components/HealthStatus';
import { toDateTime } from '../lib/formatters';
import { getMachineSizes, machineSizesMapper } from './machineSizes';
import { getMachineTypes, machineTypesMapper } from './machineTypes';
import { getPods } from './pods';
import { getRacks } from './racks';
import { getAlerts } from './alerts';
import { Badge } from '../views/MachinesView/components/Badge';
import TruncateText from '../components/Griddle/TruncateText';

const columns = ({ addAlert, machineTypes, pods, racks, role = '' }) => [
  {
    header: 'Alerts',
    isDefault: true,
    property: 'alerts',
    render: ({ alerts: { content, status, tooltip } }) => (
      <Status content={content} status={status} tooltip={tooltip} />
    ),
  },
  {
    header: 'Health',
    isDefault: true,
    property: 'bmc.health_status',
    render: ({ bmc }) =>
      bmc?.health_status ? (
        <HealthStatus status={bmc.health_status} showLabel />
      ) : null,
  },
  {
    header: 'Name',
    isDefault: true,
    property: 'name',
    render: renderLink({
      base: URL_MACHINES,
    }),
  },
  {
    header: 'ID',
    property: 'id',
  },
  {
    header: 'Serial #',
    isDefault: true,
    property: 'bmc.system_serial',
  },
  {
    header: 'State',
    isDefault: true,
    property: 'state',
    render: ({
      v2_lifecycle: v2LifeCycle,
      machine_type_id: machineTypeId,
      state,
    }) =>
      !v2LifeCycle &&
      machineTypeId === '' &&
      !state.startsWith('New') &&
      state !== 'Authentication Required' &&
      state !== 'Unknown Device'
        ? `${state} - no matching MachineType`
        : state,
  },
  {
    header: 'Pod',
    property: 'pod_id',
    render: ({ pod_id: podId }) =>
      pods.find(({ id }) => id === podId)?.name || podId,
  },
  {
    header: 'Comm status',
    isDefault: true,
    property: 'portal_comm_okay',
    render: ({ portal_comm_okay: okay }) => {
      const content = okay ? 'OK' : 'NC';
      const status = okay ? '' : 'status-warning';
      const tooltip = okay ? '' : 'Management connection lost to this machine';

      return <Status content={content} status={status} tooltip={tooltip} />;
    },
  },
  {
    header: 'Model',
    isDefault: true,
    property: 'bmc',
    render: ({ bmc }) => (
      <TruncateText content={`${bmc.manufacturer} ${bmc.model}`} />
    ),
  },
  {
    header: 'Machine sizes',
    isDefault: true,
    property: 'machine_size_ids',
  },
  {
    header: 'Rack',
    isDefault: true,
    property: 'rack_id',
    render: ({ rack_id: rackId }) =>
      racks.find(({ id }) => id === rackId)?.name || rackId,
  },
  {
    header: 'Created',
    property: 'created',
    render: ({ created }) => toDateTime(created),
  },
  {
    header: 'Modified',
    property: 'modified',
    render: ({ modified }) => toDateTime(modified),
  },
  {
    header: 'State reason',
    property: 'state_reason',
  },
  {
    header: 'Previous state',
    property: 'previous_state',
  },
  {
    header: 'Error message',
    property: 'error_message',
  },
  {
    header: 'Comm status reason',
    property: 'portal_comm_reason',
  },
  {
    header: 'Manuf.',
    property: 'hw.manuf',
    render: ({ hw, bmc }) => hw.manuf || bmc.manufacturer,
  },
  {
    header: 'CPU model',
    property: 'hw.cpu_model',
  },
  {
    header: 'CPUs',
    property: 'hw.cpus',
  },
  {
    header: 'Cores',
    property: 'hw.cores',
  },
  {
    header: 'RAM (GiB)',
    property: 'hw.ram',
    render: ({ hw }) => (hw?.ram ? hw.ram / 1024 : 0),
  },
  {
    header: 'BIOS vendor',
    property: 'hw.bios_vendor',
  },
  {
    header: 'BIOS version',
    property: 'bmc.bios_version',
  },
  {
    header: 'BIOS date',
    property: 'bmc.bios_date',
  },
  {
    header: 'BMC type',
    property: 'bmc.bmc_type',
    render: ({ bmc }) =>
      bmc?.managed_via === 'Direct' ? 'Direct attached' : bmc?.bmc_type,
  },
  {
    header: 'BMC management status',
    property: 'bmc.mgmt_status',
  },
  {
    header: 'BMC MAC',
    property: 'bmc.hw_addr',
  },
  {
    header: 'BMC user',
    property: 'bmc.user_name',
  },
  {
    header: 'BMC password',
    property: 'bmc.password',
  },
  {
    header: 'BMC IP',
    property: 'bmc.ip_addr',
    render: ({ bmc, ...props }) =>
      bmc?.ip_addr
        ? renderLink({
            base: `https://${bmc.ip_addr}`,
            external: true,
            titleKey: 'bmc.ip_addr',
          })({ ...props, bmc })
        : null,
  },
  {
    header: 'BMC IP status',
    property: 'bmc.expired',
    render: ({ bmc }) => (bmc?.expired ? 'Expired' : 'Current'),
  },
  {
    header: 'BMC hostname',
    property: 'bmc.hostname',
  },
  {
    header: 'BMC firmware version',
    property: 'bmc.bmc_fw_version',
  },
  {
    header: 'Security state',
    property: 'bmc.security_state',
  },
  {
    header: 'Secure boot enabled',
    property: 'bmc.secure_boot_enable',
    render: ({ bmc }) => (bmc.secure_boot_enable ? 'True' : 'False'),
  },
  {
    header: 'Host',
    property: 'host',
  },
  {
    header: 'Owner',
    property: 'owner',
  },
  {
    header: 'Tags',
    property: 'tags',
    render: ({ tags }) => tags.map((tag) => <Badge key={tag} tag={tag} />),
  },
  {
    header: 'Machine type',
    property: 'machine_type_id',
    render: ({ machine_type_id: machineTypeId }) =>
      machineTypes.find(({ id }) => id === machineTypeId)?.name ||
      machineTypeId,
  },
  {
    header: 'FW baseline version',
    property: 'fw_baseline_version',
  },
  ...(role.includes('_viewer')
    ? []
    : [
        {
          header: 'Actions',
          isDefault: true,
          property: 'guiactions',
          render: ({
            id,
            name,
            bmc,
            portal_comm_okay: portalCommOkay,
            state,
          }) => {
            const info = {
              machineID: id,
              consoleName: name,
              powerStatus: bmc.power_status,
              identifyStatus: bmc.identify_status,
              addAlert,
              pageType: 'machine',
              portalCommOkay,
              state,
            };
            const disabled = portalCommOkay === false;

            return <MachineActions info={info} disabled={disabled} />;
          },
        },
      ]),
];

export const getMachines = async (apiParams = {}) => {
  const response = await get(makeRestUrl(URL_MACHINES), apiParams);
  return response.json();
};

export const useGetMachines = ({ addAlert, apiParams }) => {
  const queryResults = useQueries([
    {
      queryKey: [URL_MACHINES, apiParams],
      queryFn: () => getMachines(apiParams),
    },
    {
      queryKey: URL_MACHINESIZES,
      select: (data) => data.map(machineSizesMapper),
      queryFn: getMachineSizes,
    },
    {
      queryKey: URL_MACHINETYPES,
      select: (data) => data.map(machineTypesMapper),
      queryFn: getMachineTypes,
    },
    {
      queryKey: URL_PODS,
      queryFn: getPods,
    },
    {
      queryKey: URL_RACKS,
      queryFn: getRacks,
    },
    {
      queryKey: 'MACHINE_ALERTS',
      queryFn: () => getAlerts({ source_type: 'machines' }),
    },
  ]);

  const isLoaded = queryResults.every(
    ({ error, isLoading }) => !isLoading && !error,
  );

  if (isLoaded) {
    const [machines, machineSizes, machineTypes, pods, racks, alerts] =
      queryResults;

    return {
      columns: columns({
        addAlert,
        machineSizes: machineSizes.data,
        machineTypes: machineTypes.data,
        pods: pods.data,
        racks: racks.data,
        role: auth.activeRoleDef?.id,
      })
        // adding columnName and displayName for backwards compatibility
        .map((column) => ({
          ...column,
          columnName: column.property,
          displayName: column.header,
        })),
      data: machines.data.map((machine) => {
        const filteredAlerts = alerts.data.filter(
          ({ source_id: sourceId }) => sourceId === machine.id,
        );
        const count = filteredAlerts.length;
        const content = count || '';
        const status = count ? 'status-warning' : '';
        const plural = `alert${count === 1 ? '' : 's'}`;
        const tooltip = count ? `${count} active ${plural}` : '';

        return {
          alerts: {
            content,
            status,
            tooltip,
          },
          ...machine,
          machine_size_ids: machine.machine_size_ids
            .map(
              (machineSizeId) =>
                machineSizes.data.find(({ id }) => id === machineSizeId)?.name,
            )
            .join(', '),
        };
      }),
      isLoading: false,
      refetch: (isPolling = false) => {
        machines.refetch();

        if (!isPolling) {
          machineSizes.refetch();
          machineTypes.refetch();
          pods.refetch();
          racks.refetch();
        }
      },
      error: null,
    };
  }

  return getDefaultQueriesResponse(queryResults);
};
