// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React, { useCallback, useMemo, useState } from 'react';
import { Box, CheckBox } from 'grommet';

import { HeaderCheckBox } from '../../components';

export default function useDataColumns(visibleColumns, data, noSelect) {
  const [selected, setSelected] = useState([]);

  const hasAutoConfig = useMemo(
    () => (data ?? []).some(({ immutable }) => immutable),
    [data],
  );

  const isIndeterminate = useMemo(
    () => !!selected.length && selected.length < data.length,
    [selected, data],
  );

  const isSelectAllChecked = useMemo(
    () => !!selected.length && selected.length === data.length,
    [selected, data],
  );

  const handleSelect = useCallback(
    (item) =>
      ({ target: { checked } }) => {
        if (checked) {
          setSelected([...selected, item]);
        } else {
          setSelected(selected.filter(({ id }) => item.id !== id));
        }
      },
    [selected],
  );

  const handleSelectAll = useCallback(
    ({ target: { checked } }) => {
      if ((isIndeterminate && hasAutoConfig) || !checked) {
        setSelected([]);
        return;
      }

      setSelected(data.filter(({ immutable }) => !immutable));
    },
    [isIndeterminate, hasAutoConfig, data],
  );

  const selectColumn = {
    property: 'selected',
    search: false,
    sortable: false,
    header: (
      <HeaderCheckBox
        key='selectall'
        indeterminate={isIndeterminate}
        checked={isSelectAllChecked}
        onChange={handleSelectAll}
        onClick={(e) => e.stopPropagation()}
      />
    ),
    render: (item) => (
      <Box className='select-checkbox' margin={{ left: '3px' }}>
        <CheckBox
          disabled={item.immutable}
          pad={{ horizontal: 'none' }}
          key={item.id}
          checked={selected.some(({ id }) => id === item.id)}
          onChange={handleSelect(item)}
          onClick={(e) => e.stopPropagation()}
        />
      </Box>
    ),
  };

  return {
    dataColumns: noSelect ? visibleColumns : [selectColumn, ...visibleColumns],
    selected,
    resetSelected: () => setSelected([]),
  };
}
