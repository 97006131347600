// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Tip } from 'grommet';

import { setId, makeSecItemUrl, makeSecEditUrl } from '../routes/consts';
import { Link } from '../components';
import { StatusIcon } from '../components/Griddle/ColumnStatus';
import {
  ConsoleActionsMenu,
  IdentifyButton,
  PowerButton,
} from '../components/Griddle/MachineActions';
import { get } from '../utils';

export const getDefaultQueriesResponse = (queryResults) => ({
  columns: [],
  isLoading: true,
  data: [],
  refetch: () => {
    // default empty function
  },
  error: queryResults
    .filter(({ error }) => error)
    .reduce(
      (acc, cur) => (cur ? 'Some error occurred while loading data.' : acc),
      null,
    ),
});

export const renderLink =
  ({
    base,
    icon,
    idKey = 'id',
    isEdit,
    foreignData,
    foreignKey,
    titleKey = 'name',
    external = false,
  }) =>
  (data) => {
    let url;
    let title;

    if (foreignKey && foreignData) {
      const foreignId = get(data, foreignKey);
      const item = foreignData.find(({ id }) => id === foreignId);
      title = item ? get(item, titleKey) : item;
      url = setId(
        isEdit ? makeSecEditUrl(base) : makeSecItemUrl(base),
        foreignId,
      );
    } else {
      title = get(data, titleKey);
      url = external
        ? base
        : setId(
            isEdit ? makeSecEditUrl(base) : makeSecItemUrl(base),
            get(data, idKey),
          );
    }

    return title ? (
      <Link to={url} title={title} icon={icon} external={external} />
    ) : null;
  };

export const renderMutableLink = (options) => (data) => {
  return !data?.immutable ? renderLink(options)(data) : null;
};

export const Status = ({ content, icon, status, tooltip }) => (
  <Box align='start'>
    <Tip content={tooltip}>
      <Box
        direction='row'
        round='xsmall'
        pad={{ horizontal: 'small' }}
        background={{ color: icon ? undefined : status, size: '2px' }}
      >
        {icon && <StatusIcon status={status} />}
        <span>{content}</span>
      </Box>
    </Tip>
  </Box>
);

Status.propTypes = {
  content: PropTypes.string,
  icon: PropTypes.bool,
  status: PropTypes.string,
  tooltip: PropTypes.string,
};

export const getHostMachineInfo = (data, addAlert) => {
  const hostID = data.id;
  const machineID = data.machine_id;
  const pageType = 'host';
  const consoleName = data.name;
  const powerStatus = data.power_status;
  const portalCommOkay = data.portal_comm_okay;
  let canReimage = true;
  let canReplace = true;
  let canMaintenance = true;

  if (data.power_status === 'ON') {
    canReimage = false;
    canReplace = false;
    canMaintenance = false;
  }

  if (data.state !== 'Ready') {
    canMaintenance = data.state === 'Failed' && data.workflow === 'Maintenance';
    canReimage = data.state === 'Failed';
    canReplace =
      (data.state === 'Failed' && data.workflow === 'Replace') ||
      data.workflow === 'Maintenance' ||
      data.workflow === 'Reimage';
  }

  return {
    canReimage,
    canReplace,
    canMaintenance,
    hostID,
    machineID,
    consoleName,
    powerStatus,
    addAlert,
    pageType,
    portalCommOkay,
  };
};

export const MachineActions = ({ info, disabled }) => {
  const handleClick = (e) => {
    e.stopPropagation();
  };

  return (
    <Box
      className='action-buttons'
      direction='row'
      gap='xxsmall'
      onClick={handleClick}
      focusIndicator={false}
    >
      <ConsoleActionsMenu info={info} disabled={disabled} />
      {info.pageType !== 'host' ? (
        <IdentifyButton disabled={disabled} info={info} />
      ) : null}
      <PowerButton disabled={disabled} info={info} />
    </Box>
  );
};

MachineActions.propTypes = {
  info: PropTypes.object,
  disabled: PropTypes.bool,
};

export const progressMapFunc = ({ progress, state: rowState, substate }) => {
  let style = 'info';
  let active = true;

  switch (rowState) {
    case 'Ready':
    case 'Active':
      style = 'success';
      active = false;
      break;
    case 'Failed':
      style = 'danger';
      active = false;
      break;
    default:
      break;
  }

  if (substate === 'Failed') {
    style = 'danger';
    active = false;
  } else if (substate === 'Blocked') {
    style = 'warning';
    active = true;
  }

  return {
    progress,
    label: '%({progress})s%',
    style,
    active,
  };
};
