// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import { URL_HOSTS } from '../../routes/consts';
import { useGetHosts } from '../../services/hosts';
import ListPageWithNav from '../../containers/generic/ListPageWithNav';
import { NAV_HOSTS } from '../../components/HybridNav/consts.js';

const HostListView = () => {
  return (
    <ListPageWithNav
      navSection={NAV_HOSTS}
      actions={{
        allowCreate: 'Create host',
        allowPause: true,
        poll: true,
      }}
      path={URL_HOSTS}
      sort={{
        property: 'name',
        direction: 'asc',
      }}
      title='Hosts'
      useGetData={useGetHosts}
      limitRecords={true}
    />
  );
};

export default HostListView;
