// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import settings from '../lib/settings';

export const makeUrl = (urlToMake) => {
  if (urlToMake.startsWith('/')) {
    return settings.restUrlPrefix + urlToMake;
  }
  return urlToMake;
};

export const makeProxyUrl = (urlToProxy) => settings.proxy + urlToProxy;

export const makeRestUrl = (urlToMakeRest) => URL_RESTPATH + urlToMakeRest;
export const makePubUrl = (urlToMakePub) => URL_PUBPATH + urlToMakePub;

export const makeNoNavUrl = (urlToMakeNoNav) => URL_NONAVPATH + urlToMakeNoNav;

export const makeSecUrl = (urlToMakeSecure) => URL_SECUREPATH + urlToMakeSecure;

export const makeItemUrl = (urlToMakeItem) => `${urlToMakeItem}/:id`;

export const makeCreateUrl = (urlToMakeCreate) => `${urlToMakeCreate}/create`;

export const makeEditUrl = (urlToMakeEdit) =>
  `${makeItemUrl(urlToMakeEdit)}/edit`;

export const makeUpdateUrl = (urlToMakeUpdate) => `${urlToMakeUpdate}/update`;

export const makeSecItemUrl = (urlToMakeSecItem) =>
  makeSecUrl(makeItemUrl(urlToMakeSecItem));

export const makeRestItemUrl = (urlToMakeRestItem) =>
  makeRestUrl(makeItemUrl(urlToMakeRestItem));

export const makeSecCreateUrl = (urlToMakeSecCreate) =>
  makeSecUrl(makeCreateUrl(urlToMakeSecCreate));

export const makeSecEditUrl = (urlMakeSecEdit) =>
  makeSecUrl(makeEditUrl(urlMakeSecEdit));

export const makeSecUpdateUrl = (urlToMakeSecUpdate) =>
  makeSecUrl(makeUpdateUrl(urlToMakeSecUpdate));

export const makeSecChildUrl = (urlToMakeSecChild, childPath) =>
  makeSecUrl(makeChildUrl(urlToMakeSecChild, childPath));

export const makeSecChildCreateUrl = (urlToMakeSecChildCreate, childPath) =>
  makeCreateUrl(makeSecChildUrl(urlToMakeSecChildCreate, childPath));

export const makeSecChildEditUrl = (urlToMakeSecChildEdit, childPath) =>
  makeEditUrl(makeSecChildUrl(urlToMakeSecChildEdit, childPath));

export const makeSecChildItemUrl = (urlToMakeSecChildItem, childPath) =>
  makeItemUrl(makeSecChildUrl(urlToMakeSecChildItem, childPath));

export const setId = (urlToSetId, id) => urlToSetId.replace(':id', id);

export const setParentId = (urlToSetParentId, pid) =>
  urlToSetParentId.replace(':pid', pid);

export const makeChildUrl = (urlToMakeChild, childPath) =>
  `${urlToMakeChild}/:pid/${childPath}`;

export const url = {
  makeUrl,
  makeProxyUrl,
  makePubUrl,
  makeNoNavUrl,
  makeSecUrl,
  makeItemUrl,
  makeCreateUrl,
  makeUpdateUrl,
  makeRestUrl,
  makeRestItemUrl,
  makeSecItemUrl,
  makeSecCreateUrl,
  makeSecUpdateUrl,
  setId,
  setParentId,
  makeChildUrl,
  makeSecChildUrl,
};

export const CONFIDENTIAL = false;
export const URL_RESTPATH = '/rest/';
export const URL_PUBPATH = '/p/';
export const URL_SECUREPATH = '/s/';
export const URL_LOGIN = `${settings.restUrlPrefix}/auth/login`;
export const URL_LOGOUT = makeRestUrl('auth/logout');
export const URL_USER_INFO = makeRestUrl('auth/userinfo');
export const URL_NONAVPATH = '/nonav/';
export const URL_NOAUTHPATH = '/noauth/';
export const URL_WELCOME = 'welcome';
export const URL_INVITE = 'invite';
export const URL_INVITE_PUBLIC = makePubUrl(URL_INVITE);
export const URL_NOTFOUND = '404';
export const URL_HOME = 'home';
export const URL_DASHBOARD = 'dashboard';
export const URL_HOSTER_DASHBOARD = 'hosters/:id/dashboard';
export const URL_HOSTGROUPS = 'host-groups';
export const URL_HOSTS = 'hosts';
export const URL_USAGE = 'hosts/usage';
export const URL_VOLUME_USAGE = 'volumes/usage';

export const URL_AVAIL_PROJECT_RESOURCES = 'projects/available-resources';
export const URL_AVAIL_RESOURCES = 'hosts/available-resources';
export const URL_AVAIL_NETWORK_RESOURCES = 'networks/available-resources';
export const URL_HOST_CONSOLELOGS = 'hosts/:id/consolelogs';
export const URL_MACHINES = 'machines';
export const URL_MACHINES_SET_TAGS = 'machines/settags';
export const URL_MACHINESIZES = 'machinesizes';
export const URL_MACHINETYPES = 'machinetypes';
export const URL_FWBASELINES = 'fwbaselines';
export const URL_PODS = 'pods';
export const URL_PODTYPES = 'podtypes';
export const URL_NETWORKS = 'networks';
export const URL_PRIVATE_NETWORKS = 'Private networks';
export const URL_NETWORKTYPES = 'networktypes';
export const URL_NETWORKPURPOSES = 'networkpurposes';
export const URL_NETWORKPOLICIES = 'networkpolicies';
export const URL_TAGS = 'tags';
export const URL_RACKS = 'racks';
export const URL_RACKCONTROLLERS = 'rackcontrollers';
export const URL_RACKCONTROLLER_UPGRADE = 'rackcontrollers/:id/softwareupdate';
export const URL_RACKCONTROLLER_QUERY = 'rackcontrollers/:id/softwarequery';
export const URL_RACKTEMPLATES = 'racktemplates';
export const URL_OPS = 'ops';
export const URL_ACTIONS = 'actions';
export const URL_IPPOOLS = 'ippools';
export const URL_IPPOOL_ALLOCATION = 'ippools/:id/allocation';
export const URL_IPPOOL_RETURN = 'ippools/:id/return';
export const URL_IPPOOL_FACTORIES = 'ippoolfactories';
export const URL_IPPOOL_FACTORY = 'ippoolfactories/:id';
export const URL_FILES = 'files';
export const URL_SERVICES = 'services';
export const URL_HOSTER_SERVICES = 'hosterservices';
export const URL_HOSTER_SERVICE_CATALOG = 'hosterservicecatalog';
export const URL_SSHKEYS = 'sshkeys';
export const URL_SWITCHES = 'switches';
export const URL_SWITCHTYPES = 'switchtypes';
export const URL_SWITCHPORTS = 'ports';
export const URL_FIRMWARE = 'switches/:id/firmware';
export const URL_PORTALS = 'portals';
export const URL_HOSTERS = 'hosters';
export const URL_PROJECTS = 'projects';
export const URL_USERS = 'users';
export const URL_DOWNLOADS = 'downloads';
export const URL_RELEASES = 'releases';
export const URL_ARRAYS = 'arrays';
export const URL_ARRAYTYPES = 'arraytypes';
export const URL_VOLUME_FLAVOR = 'volumeflavors';
export const URL_VOLUME_FLAVOR_CLASSIFIER_INFO = `${URL_VOLUME_FLAVOR}/classifierinfo`;
export const URL_STORAGE_GROUPS = 'storagegroups';
export const URL_STORAGE_POOLS = 'capacitypools';
export const URL_STORAGE_SYSTEMS = 'storagesystems';
export const URL_STORAGE_INTERFACES = 'storageinterfaces';
export const URL_VOLUMES = 'volumes';
export const URL_VOLUME_FLAVORS = 'volume-flavors';
export const URL_ATTACH_VOLUME = 'volumes/:id/attach';
export const URL_DETTACH_VOLUME = 'volumes/:id/detach';
export const URL_VOLUME_ATTACHMENTS = 'volumeattachments';
export const URL_VOLUME_GROUPS = 'volumegroups';
export const URL_AVAIL_STORAGE_RESOURCES = 'volumes/available-resources';
export const URL_DEVICES = 'devices';
export const URL_ORGANIZATIONS = 'organizations';
export const URL_ALERTS = 'alerts';
export const URL_TASKS = '/tasks';
export const URL_LOGS = '/logs';
export const URL_ALERTS_MACHINES = 'alerts/machines';
export const URL_USER_OPS = 'userops';
export const URL_MACHINE_SIZE_CLASSIFIER_INFO = 'machinesizes/classifierinfo';
export const URL_MACHINE_TYPE_CLASSIFIER_INFO = 'machinetypes/classifierinfo';
export const URL_MACHINE_OS_CLASSIFIER_INFO = 'machineos/classifierinfo';
export const URL_RESET_STATE = '/reset-state';
export const URL_IMPORT_EXT_VOL = 'externalvolumeimport';

// sub resources
export const URL_SETTINGS = 'settings';
export const URL_PROFILES = 'profiles';
export const URL_MEMBERS = 'members';
export const URL_NOTES = 'notes';
export const URL_STATE = 'state';
export const URL_CERT = 'cert-info';

// allows requiring specific roles for certain routes, even
// if they have rw permission for that resource
// from model/member.go
export const SCOPE_PORTAL = 'portal';
export const SCOPE_HOSTER = 'hoster';
export const SCOPE_PROJECT = 'project';
export const SCOPE_ANY = [SCOPE_PORTAL, SCOPE_HOSTER, SCOPE_PROJECT];

// from /portal/role/access, but using ordered values for simplified checks
// precedence of permissions READ < READ_WRITE < SUPER
// none does not require any permissions, e.g. for homepage currently
export const ACCESS_NONE = 0;
export const ACCESS_READ = 1;
export const ACCESS_READ_WRITE = 2;
export const ACCESS_SUPER = 3;

// one off homepage resource for consistency
export const RESOURCE_HOME = 'home_page';
export const RESOURCE_USAGE = 'host_usage_page';
export const RESOURCE_VOLUME_USAGE = 'volume_usage_page';
export const RESOURCE_DASHBOARD = 'dashboard_page';
export const RESOURCE_DOWNLOADS = 'downloads_page';

// from portal/pkg/role/resource
export const RESOURCE_HOSTGROUP = 'host_group';
export const RESOURCE_HOSTTEMPLATE = 'host_template';
export const RESOURCE_HOST = 'host';
export const RESOURCE_SSHKEY = 'sshkey';
export const RESOURCE_IPPOOL = 'ippool';
export const RESOURCE_OSFLAVOR = 'os_flavor';
export const RESOURCE_MACHINE = 'machine';
export const RESOURCE_MACHINESIZE = 'machinesize';
export const RESOURCE_MACHINETYPE = 'machinetype';
export const RESOURCE_FWBASELINE = 'fwbaseline';
export const RESOURCE_SERVICE = 'service';
export const RESOURCE_SWITCH = 'switch';
export const RESOURCE_SWITCHTYPE = 'switchtype';
export const RESOURCE_SWITCHPORT = 'port';
export const RESOURCE_POD = 'pod';
export const RESOURCE_NETWORK = 'network';
export const RESOURCE_NETWORKPOLICY = 'networkpolicy';
export const RESOURCE_TAG = 'tag';
export const RESOURCE_RACKTEMPLATE = 'racktemplate';
export const RESOURCE_RACK = 'rack';
export const RESOURCE_RACKCONTROLLER = 'rackcontroller';
export const RESOURCE_OPREQUEST = 'op';
export const RESOURCE_OPABORT = 'opabort';
export const RESOURCE_FILE = 'file';
export const RESOURCE_PORTAL = 'portal';
export const RESOURCE_HOSTER = 'hoster';
export const RESOURCE_PROJECT = 'project';
export const RESOURCE_USER = 'user';
export const RESOURCE_MEMBER = 'member';
export const RESOURCE_RELEASE = 'release';
export const RESOURCE_ARRAY = 'array';
export const RESOURCE_VOLUME = 'volume';
export const RESOURCE_VOLUME_FLAVOR = 'volumeflavor';
export const RESOURCE_VOLUME_ATTACHMENTS = 'volumeattach';
export const RESOURCE_DEVICE = 'device';
export const RESOURCE_ORGANIZATION = 'organization';
export const RESOURCE_STORAGE_POOL = 'capacitypool';
export const RESOURCE_STORAGE_GROUP = 'storagegroup';
export const RESOURCE_STORAGE_SYSTEM = 'storagesystem';
export const RESOURCE_ALERT = 'alert';

// precedence of permissions READ < READ_WRITE < SUPER
// allows simplified min access required expression
// for adding routes
export const GET = 'get';
export const PUT = 'put';
export const POST = 'post';
export const DELETE = 'delete';

export const OKTA = 'okta';
