// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import MetaBuilder, { SMALL } from '../../containers/generic/MetaBuilder';
import { URL_VOLUMES, URL_HOSTS } from '../../routes/consts';
import auth from '../../lib/auth';
import debugLogger from '../../lib/debug';
import * as log from '../../lib/debug';
import * as c from '../../routes/consts';

const debug = debugLogger('VolumeView::index::', log.LOG_LEVEL_DEBUG);

export default function volumeAttachmentsAdd(props) {
  const m = new MetaBuilder(props);

  const dsVolumes = m.newDataSource(URL_VOLUMES);
  const dsHosts = m.newDataSource(URL_HOSTS);
  const dsVolumeFlavors = m.newStaticDataSource([]);
  const dsStoragePools = m.newStaticDataSource([]);
  const dsVolumeCollections = m.newStaticDataSource([]);

  const dsAvail = m
    .newDataSource(c.URL_AVAIL_STORAGE_RESOURCES)
    .OnLoad(({ data }) => {
      const uniqueFlavors = getUniqueFlavors(
        data?.inventory,
        data?.locations?.[0].id,
      );

      dsVolumeFlavors.SetData([
        { id: 'select_volume_flavor', name: 'Select volume flavor' },
        ...uniqueFlavors,
      ]);
      dsStoragePools.SetData([{ id: 'auto', name: 'Auto' }]);
      dsVolumeCollections.SetData([{ id: 'none', name: 'None' }]);
    });

  const inHosterScope = auth.inScope(c.SCOPE_HOSTER);
  const dsProjects = inHosterScope ? m.newDataSource(c.URL_PROJECTS) : [];

  debug.debug('metaCreate: inHosterScope:', inHosterScope);

  // If hoster, select project that will own the host (for on-behalf-of)
  if (inHosterScope) {
    m.addField('_temp.project', 'On behalf of project')
      .Input()
      .DropDown()
      .DataXform(dsProjects, (json) => {
        // using id of 'self' because a value of '' causes loop
        const tms = [{ id: 'self', name: 'Self' }];
        return tms.concat(json.map((t) => ({ id: t.id, name: t.name })));
      })
      .MaxWidth(400)
      .OnChange((val) => {
        let value = val;

        if (value !== undefined) {
          if (value === 'self') {
            dsAvail.DeleteQuery('project');
            value = '';
          } else {
            dsAvail.AddQuery('project', value);
          }

          dsAvail.Fetch();
          m.view.setFormValue('project_id', value);
        }
      });
  }

  m.addColumn('name', 'Name').Input().MaxWidth(SMALL);

  m.addColumn('hostID', 'Host')
    .Input()
    .Required()
    .DropDown()
    .DataXform(dsHosts, (json) => json.map((t) => ({ id: t.id, name: t.name })))
    .MaxWidth(SMALL);

  m.addColumn('volumeID', 'Volume')
    .Input()
    .Required()
    .DropDown()
    .DataXform(dsVolumes, (json) =>
      json
        .filter(
          ({ shareable, state, unmanaged_volume }) =>
            !unmanaged_volume &&
            (state === 'allocated' || (state === 'visible' && shareable)),
        )
        .map(({ id, name }) => ({ id, name })),
    )
    .MaxWidth(SMALL);

  return m;
}
