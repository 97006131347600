// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import defaultTo from 'lodash/defaultTo';
import _isFinite from 'lodash/isFinite';
import toNumber from 'lodash/toNumber';
import { Box, Layer, Text } from 'grommet';
import { FormPrevious } from 'grommet-icons';
import assert from '../../lib/assert';
import debugLogger from '../../lib/debug';
import * as log from '../../lib/debug';
import ViewHeader from '../../components/ViewHeader';
import View from '../../components/View';
import * as c from '../../routes/consts';
import MetaForm from './MetaForm';
import * as mb from './MetaBuilder';
import styled from 'styled-components';
import { Alert } from '../../components';
import ErrorDialog from '../../components/controls/ErrorDialog';
import perm from '../../routes/permissions';
import auth from '../../lib/auth';
import { NavLink } from 'react-router-dom';

const debugLevel = log.LOG_LEVEL_DEBUG;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: inherit;
  margin-top: 0;
`;

const assertValidSettings = (s) => {
  assert.defined(s.title, 'title');
  assert.defined(s.homeUrl, 'homeUrl');
  assert.defined(s.baseUrl, 'baseUrl');
  assert.defined(s.authUrl, 'authUrl');
  assert.defined(s.meta, 'meta()', ', check the meta file for this view');
};

const Alerts = ({ alerts }) => {
  if (alerts.length === 0) {
    return null;
  }

  return (
    <Layer
      position='bottom'
      modal={false}
      margin={{ vertical: 'medium', horizontal: 'small' }}
      responsive={false}
      plain
    >
      <Box gap='medium'>
        {alerts.map((item) => (
          <Alert key={item.id} status={defaultTo(item.style, 'info')}>
            {item.msg}
          </Alert>
        ))}
      </Box>
    </Layer>
  );
};

export default function ItemViewContainer(settings) {
  const className = `ItemViewContainer<${settings.title}>`;
  const debug = debugLogger(className, debugLevel);
  assertValidSettings(settings);

  class ItemViewContainer extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        alerts: [],
        form: {},
        errDialog: { show: false },
      };

      this.alertID = 0;
    }

    static propTypes = {
      history: PropTypes.object,
    };

    UNSAFE_componentWillMount() {
      this.homeUrl = settings.homeUrl;
      this.baseUrl = settings.baseUrl;

      // if a subresource then adjust urls
      if (this.props.match.params?.pid) {
        this.homeUrl = c.setParentId(
          settings.homeUrl,
          this.props.match.params.pid,
        );
        this.baseUrl = c.setParentId(
          settings.baseUrl,
          this.props.match.params.pid,
        );
      }

      this.editUrl = defaultTo(
        settings.editUrl,
        c.makeSecEditUrl(this.baseUrl),
      );
      this.editUrl = c.setId(this.editUrl, this.props.match.params.id);
    }

    addAlert = (msg, style) => {
      const id = this.alertID++;
      const a = { id, msg, modified: 1, style };
      this.setState({ alerts: [...this.state.alerts, a] });
      setTimeout(() => {
        this.removeAlert(id);
      }, 5000);
    };

    removeAlert = (id) => {
      const visible = this.state.alerts.filter((a) => a.id !== id);
      this.setState({ alerts: visible });
    };

    cancelScreen = () => {
      debug.log('cancelScreen', this.homeUrl);
      this.props.history.push(this.homeUrl);
    };

    editScreen = (form, activeTab) => {
      debug.debug('editScreen', this.editUrl, activeTab, typeof activeTab);
      if (_isFinite(activeTab)) {
        debug.debug('editScreen activeTab was determined to be Finite');
        this.props.history.push(`${this.editUrl}/${activeTab}`);
      } else {
        debug.debug('editScreen activeTab was determined NOT to be Finite');
        this.props.history.push(this.editUrl);
      }
    };

    onInitForm = (form) => {
      this.setState({ form });
    };

    showErrorDialog = (title, body) => {
      this.setState({ errDialog: { show: true, title, body } });
    };

    render() {
      let editProps = {};

      if (!this.state.form.immutable) {
        const canEdit =
          typeof settings.allowEdit === 'function'
            ? settings.allowEdit(this.state.form)
            : settings.allowEdit;

        if (
          canEdit &&
          perm.authorized(settings.authUrl, c.PUT, auth.activeRoleDef)
        ) {
          if (settings.onSubmit) {
            editProps = {
              submitLabel: settings.submitLabel || 'Edit',
              onSubmit: settings.onSubmit,
            };
          } else {
            editProps = {
              submitLabel: 'Edit',
              onSubmit: this.editScreen,
            };
          }
        }
      }
      return (
        <View>
          {settings.homeLabel && (
            <StyledNavLink to={settings.homeUrl} activeClassName='active'>
              <Box direction='row'>
                <Box pad={{ top: '3px' }}>
                  <FormPrevious />
                </Box>
                {settings.homeLabel}
              </Box>
            </StyledNavLink>
          )}
          <ViewHeader
            icon={settings.icon}
            controls={
              settings.HeadingControls && (
                <settings.HeadingControls
                  form={this.state.form}
                  addAlert={this.addAlert}
                />
              )
            }
          >
            {settings.title}
          </ViewHeader>
          <Alerts alerts={this.state.alerts} />
          <MetaForm
            meta={settings.meta}
            className={className}
            activeTab={toNumber(this.props.match.params.tab)}
            mode={mb.MODE_VIEW}
            itemId={this.props.match.params.id}
            itemUrl={this.baseUrl}
            readOnly
            showAllFields
            {...editProps}
            cancelLabel={settings.cancelLabel || 'Back'}
            onCancel={settings.onCancel || this.cancelScreen}
            addAlert={this.addAlert}
            onInitForm={this.onInitForm}
          />
          <ErrorDialog
            show={this.state.errDialog.show}
            title={this.state.errDialog.title}
            body={this.state.errDialog.body}
            onDismiss={() => this.setState({ errDialog: { show: false } })}
          />
        </View>
      );
    }
  }

  return ItemViewContainer;
}
