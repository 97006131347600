// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import { useContext } from 'react';
import { DELETE, makeRestUrl } from '../../routes/consts';
import { del, errorInfo, patch, post } from '../../lib/rest';
import { AlertsContext } from '../context/alertsContext';
import { useMembershipContext } from '../context/membershipContext';
import permissions from '../../routes/permissions';
import {
  ErrorsAction,
  ErrorsFailInlineNotification,
} from '../../components/ErrorsFailInlineNotification';
import { useNotificationInlineContext } from '../context/notificationInlineContext';

export default function useHeaderActions({
  actions,
  selected,
  refetch,
  resetSelected,
}) {
  const { allowImport, allowPublish, allowUpdate, base, title } = actions;
  const { addAlert } = useContext(AlertsContext);
  const { role } = useMembershipContext();
  const { setInlineNotification } = useNotificationInlineContext();
  const canDelete =
    permissions.authorized(base, DELETE, role) || actions.allowDelete;

  const handleDelete =
    canDelete && selected.length
      ? async () => {
          const success = [];
          const error = [];

          const items = selected.map((item) => {
            return {
              item,
              handle: del(`/rest/${base}/${item.id}`)
                .then((res) => {
                  if (!res.ok) {
                    error.push({ ...item, error: res.statusText });
                  } else {
                    success.push({ ...item });
                  }
                })
                .catch((err) => {
                  error.push({
                    ...item,
                    errorMessage: err.text ?? err.message,
                  });
                }),
            };
          });
          await Promise.all(items.map((item) => item.handle));

          const deletingOneItem = selected.length === 1;
          if (!error.length) {
            addAlert(
              deletingOneItem
                ? `${success[0].name} deleted.`
                : `Deleted ${success.length} items.`,
            );
          } else {
            setInlineNotification(
              base,
              <ErrorsFailInlineNotification
                status={{ success, error }}
                itemTitle={title}
                errorsAction={ErrorsAction.delete}
              />,
            );
            addAlert(
              deletingOneItem
                ? `Unable to delete ${error[0].name}: ${error[0].errorMessage}`
                : `Deleted ${success.length} out of ${selected.length} items, ${error.length} failed.`,
              selected.length === error.length ? 'danger' : 'warning',
            );
          }

          refetch?.();
          resetSelected();
        }
      : undefined;

  const handleImport =
    allowImport && selected.length
      ? () => {
          for (const item of selected) {
            const service = {
              ...item,
              hostere_id: undefined,
              hoster_use: true,
              id: undefined,
              name: `${item.name}(Copy)`,
            };

            const cloneUrl = `${makeRestUrl(base)}/${item.id}/clone`;

            post(cloneUrl, service)
              .then(() => {
                addAlert(`${item.name} imported`);

                if (refetch) {
                  refetch();
                }
              })
              .catch((err) =>
                errorInfo(err, (error) => {
                  addAlert(
                    `Unable to import ${item.name}: ${error.text}`,
                    'danger',
                  );
                }),
              );
          }

          return true;
        }
      : undefined;

  const handlePublish =
    allowPublish && selected.length
      ? (event) => {
          event.preventDefault();

          const data = [{ op: 'replace', path: '/hoster_id', value: '*' }];

          for (const item of selected) {
            patch(makeRestUrl(`${base}/${item.id}`), data)
              .then((res) => {
                if (!res.ok) {
                  addAlert(
                    `Unable to publish ${item.name}: ${res.statusText}`,
                    'danger',
                  );
                } else {
                  addAlert(`${item.name} published`);

                  if (refetch) {
                    refetch();
                  }
                }
              })
              .catch((err) =>
                errorInfo(err, (error) => {
                  addAlert(
                    `Unable to publish ${item.name}: ${error.text}`,
                    'danger',
                  );
                }),
              );
          }
        }
      : undefined;

  const handleUnpublish =
    allowPublish && selected.length
      ? (event) => {
          event.preventDefault();

          const data = [{ op: 'replace', path: '/hoster_id', value: '' }];

          for (const item of selected) {
            patch(makeRestUrl(`${base}/${item.id}`), data)
              .then((res) => {
                if (!res.ok) {
                  addAlert(
                    `Unable to unpublish ${item.name}: ${res.statusText}`,
                    'danger',
                  );
                } else {
                  addAlert(`${item.name} unpublished`);

                  if (refetch) {
                    refetch();
                  }
                }
              })
              .catch((err) =>
                errorInfo(err, (error) => {
                  addAlert(
                    `Unable to unpublish ${item.name}: ${error.text}`,
                    'danger',
                  );
                }),
              );
          }
        }
      : undefined;

  const handleUpdate =
    allowUpdate && selected.length ? () => allowUpdate(selected) : undefined;

  return {
    handleDelete,
    handleImport,
    handlePublish,
    handleUnpublish,
    handleUpdate,
  };
}
