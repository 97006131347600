// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import HttpStatus from 'http-status-codes';
import { sprintf } from 'sprintf-js';
import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import toNumber from 'lodash/toNumber';
import rest from '../../lib/rest';
import assert from '../../lib/assert';
import debugLogger from '../../lib/debug';
import * as c from '../../routes/consts.js';
import ViewHeader from '../../components/ViewHeader';
import View from '../../components/View';
import ErrorDialog from '../../components/controls/ErrorDialog';
import MetaForm from './MetaForm';
import * as mb from './MetaBuilder';
import { UnmanagedSwitchConfirmModal } from '../../components/controls/UnmanagedSwitchConfirmModal';

const debugEnabled = true;

const assertValidSettings = (s) => {
  assert.defined(s.title, 'title');
  assert.defined(s.homeUrl, 'homeUrl');
  assert.defined(s.baseUrl, 'baseUrl');
  assert.defined(s.authUrl, 'authUrl');
  assert.defined(s.meta, 'meta()', ', check the meta file for this view');
};

export default function EditViewContainer(settings) {
  const className = `EditViewContainer<${settings.title}>`;
  const debug = debugLogger(className, debugEnabled);
  assertValidSettings(settings);

  class EditViewContainer extends React.Component {
    static propTypes = {
      history: PropTypes.object,
      confirmComponent: PropTypes.object,
    };

    constructor(props) {
      super(props);
      this.state = {
        errDialog: { show: false },
        conflictDialog: { show: false },
        confirmDialog: { show: false, form: null },
      };
    }

    showErrorDialog = (title, body) => {
      this.setState({ errDialog: { show: true, title, body } });
    };

    showConfictDialog = (title, body, form) => {
      this.setState({
        conflictDialog: { show: true, title, body, form },
      });
    };

    conflictForceOverwrite = () => {
      const { form } = this.state.conflictDialog;
      form.etag = '*';
      this.submit(form);

      this.setState({ conflictDialog: { show: false } });
    };

    UNSAFE_componentWillMount() {
      this.homeUrl = settings.homeUrl;
      this.baseUrl = settings.baseUrl;
      this.restBaseUrl = c.makeRestUrl(settings.baseUrl);

      // if a subresource then adjust urls
      if (this.props.match.params?.pid) {
        this.homeUrl = c.setParentId(
          settings.homeUrl,
          this.props.match.params.pid,
        );
        this.baseUrl = c.setParentId(
          settings.baseUrl,
          this.props.match.params.pid,
        );
        this.restBaseUrl = c.makeRestUrl(
          c.setParentId(settings.baseUrl, this.props.match.params.pid),
        );
      }

      this.restItemUrl = c.makeItemUrl(this.restBaseUrl);
    }

    cancelScreen = () => {
      this.props.history.push(this.homeUrl);
    };

    handleConfirm = () => {
      this.setState(
        (prevState) => ({
          ...prevState,
          confirmDialog: {
            ...prevState.confirmDialog,
            show: false,
          },
        }),
        () => this.submit(this.state.confirmDialog.form, true),
      );
    };

    submit = (form, force) => {
      debug.log('submit()==>form:', form);
      const activeTab = this.props.match.params.tab;
      const { id } = this.props.match.params;

      // Post to database
      const shouldConfirmPodChange = () =>
        form.resource_type === 'pod' &&
        !form._temp.unmanaged &&
        form.pod_features.unmanaged_switch &&
        !force;

      if (shouldConfirmPodChange()) {
        this.setState({ confirmDialog: { show: true, form } });

        return;
      }

      if (
        (form.resource_type === 'ippool' ||
          form.allocations?.length ||
          form.releases?.length) &&
        Number(activeTab) === 2
      ) {
        const promises = [];

        if (form.allocations?.length) {
          promises.push(
            rest.post(`/rest/ippools/${id}/allocation`, form.allocations),
          );
        }

        if (form.releases?.length) {
          promises.push(rest.post(`/rest/ippools/${id}/return`, form.releases));
        }

        Promise.all(promises)
          .then(() => {
            const itemViewPage = this.props.history.location.pathname
              .split('/')
              .slice(0, -2)
              .join('/');
            this.props.history.push(itemViewPage);
          })
          .catch((err) =>
            rest.errorInfo(err, (errInfo) => {
              switch (errInfo.status) {
                case HttpStatus.CONFLICT:
                  this.showConfictDialog('Edit conflict', errInfo.text, form);
                  break;
                default:
                  this.showErrorDialog('Edit error', errInfo.text);
              }
            }),
          );
      } else if (form.bmc?.ip_pool_id === 'new_ip_pool') {
        const { payload, ...restData } = form;

        rest
          .post('/rest/ippools', payload)
          .then((res) => res.json())
          .then((ippool) =>
            rest.put(c.setId(this.restItemUrl, id), {
              ...restData,
              bmc: {
                ...restData.bmc,
                ip_pool_id: ippool.id,
              },
            }),
          )
          .then((res) => this.handleSubmitResults(res))
          .catch((err) =>
            rest.errorInfo(err, (errInfo) => {
              this.showErrorDialog('Edit error', errInfo.text);
            }),
          );
      } else if (form.ip_pool === 'new') {
        const { ip_pool, payload, ...restData } = form;

        rest
          .post('/rest/ippools', payload)
          .then((res) => res.json())
          .then((ippool) =>
            rest.put(c.setId(this.restItemUrl, id), {
              ...restData,
              ip_pool_id: ippool.id,
            }),
          )
          .then((res) => this.handleSubmitResults(res))
          .catch((err) =>
            rest.errorInfo(err, (errInfo) => {
              this.showErrorDialog('Edit error', errInfo.text);
            }),
          );
      } else {
        let portUsage;
        const payload = {
          ...form,
          ...(form.ext_vol_import_polling_interval_minutes
            ? {
                ext_vol_import_polling_interval_minutes: Number(
                  form.ext_vol_import_polling_interval_minutes,
                ),
              }
            : {}),
        };

        if (
          payload.resource_type === 'racktemplate' &&
          !isNil(payload.switch_sets?.[0])
        ) {
          portUsage = payload.switch_sets[0].port_usage2;
        }

        if (
          payload.resource_type === 'rack' &&
          !isNil(payload.template_config?.switch_sets?.[0])
        ) {
          portUsage = payload.template_config.switch_sets[0].port_usage2;
        }

        if (portUsage) {
          portUsage = Object.keys(portUsage).reduce((acc, key) => {
            acc[key] =
              key === '__uniqueid'
                ? portUsage[key]
                : portUsage[key].filter(
                    ({ speed, ports }) => !!speed && !!ports?.length,
                  );

            return acc;
          }, {});
        }

        if (
          payload.resource_type === 'racktemplate' &&
          !isNil(payload.switch_sets[0])
        ) {
          payload.switch_sets[0].port_usage2 = portUsage;
        }

        if (
          payload.resource_type === 'rack' &&
          !isNil(payload.template_config?.switch_sets?.[0])
        ) {
          payload.template_config.switch_sets[0].port_usage2 = portUsage;
        }

        if (payload.resource_type === 'rack' && isNil(payload.controller_id)) {
          payload.controller_id = '';
        }

        rest
          .put(c.setId(this.restItemUrl, id), payload)
          .then((res) => this.handleSubmitResults(res))
          .catch((err) =>
            rest.errorInfo(err, (errInfo) => {
              switch (errInfo.status) {
                case HttpStatus.CONFLICT:
                  this.showConfictDialog('Edit conflict', errInfo.text, form);
                  break;
                default:
                  this.showErrorDialog('Edit error', errInfo.text);
              }
            }),
          );
      }
    };

    saveAsNew = (data) => {
      debug.log('saveAsNew', data);

      let payload;

      if (isFunction(settings.saveAsNewPrep)) {
        payload = settings.saveAsNewPrep(data);
      } else {
        payload = { ...data, id: '' };
      }

      // Post to database
      rest
        .post(this.restBaseUrl, payload)
        .then((res) => this.handleSubmitResults(res))
        .catch((err) =>
          rest.errorInfo(err, (errInfo) => {
            this.showErrorDialog('Edit error', errInfo.text);
          }),
        );
    };

    handleSubmitResults = (res) => {
      debug.log('handleSubmitResults', res);
      if (!res.ok) {
        this.showErrorDialog(
          'Edit error',
          sprintf(
            'Error found during submit: %s %s.',
            res.status,
            res.statusText,
          ),
        );
      } else if (isNil(this.props.confirmComponent)) {
        this.cancelScreen();
      } else {
        res.json().then((json) => {
          this.setState({ editResult: json });
        });
      }
    };

    handleConfirmModalClose = () =>
      this.setState({ confirmDialog: { show: false } });

    render() {
      if (
        !isNil(this.props.confirmComponent) &&
        !isNil(this.state) &&
        !isNil(this.state.editResult)
      ) {
        return React.createElement(this.props.confirmComponent, {
          exit: this.cancelScreen,
          result: this.state.editResult,
        });
      }

      return (
        <View>
          <ErrorDialog
            key='errDialog'
            show={this.state.errDialog.show}
            title={this.state.errDialog.title}
            body={this.state.errDialog.body}
            onDismiss={() => this.setState({ errDialog: { show: false } })}
          />
          <ErrorDialog
            key='conflictDialog'
            show={this.state.conflictDialog.show}
            title={this.state.conflictDialog.title}
            body={this.state.conflictDialog.body}
            onDismiss={() => this.setState({ conflictDialog: { show: false } })}
            dismissText='Cancel'
            onAction1={this.conflictForceOverwrite}
            action1Text='Force Overwrite'
          />
          <ViewHeader title icon={settings.icon}>
            {settings.title}
            <span />
            {/* make children an array for prop validation */}
          </ViewHeader>
          <MetaForm
            meta={settings.meta}
            className={className}
            activeTab={toNumber(this.props.match.params.tab)}
            mode={mb.MODE_EDIT}
            itemId={this.props.match.params.id}
            itemUrl={this.baseUrl}
            submitLabel='Save'
            onSubmit={this.submit}
            cancelLabel='Cancel'
            onCancel={this.cancelScreen}
          />
          <UnmanagedSwitchConfirmModal
            onClose={this.handleConfirmModalClose}
            onConfirm={this.handleConfirm}
            willShow={this.state.confirmDialog.show}
          />
        </View>
      );
    }
  }

  return EditViewContainer;
}
