// (C) Copyright 2016-2024 Hewlett Packard Enterprise Development LP
import { makeSecUrl, URL_VOLUME_FLAVOR as BASE_URL } from '../../routes/consts';
import ItemViewContainer from '../../containers/generic/ItemViewContainer';
import EditViewContainer from '../../containers/generic/EditViewContainer';
import CreateViewContainer from '../../containers/generic/CreateViewContainer';
import volumeFlavorAdd from './volumeFlavorAdd';
import VolumeFlavorListViewPage from './VolumeFlavorListView';
import { LABEL_FLAVORS } from '../../components/HybridNav/consts.js';

const settings = {
  // used to filter create/trash icons from the view;
  // using the defined roledef permissions mappings to this auth URL entry
  authUrl: BASE_URL,
  // homepage for this list view (e.g. /s/hosters/:pid/)
  // is where this view is located; for return from create / item views
  homeUrl: makeSecUrl(BASE_URL),
  homeLabel: LABEL_FLAVORS,
  // base url to be used for creating all associated
  // URLS for this reasource, e.g. pageItem, pageCreate, restUrl, restItemUrl
  baseUrl: BASE_URL,
};

export const VolumeFlavorListView = VolumeFlavorListViewPage;

export const VolumeFlavorItemView = ItemViewContainer({
  ...settings,
  meta: (props) => volumeFlavorAdd(props),
  allowEdit: ({ immutable }) => !immutable,
  title: 'Volume flavor',
});

export const VolumeFlavorEditView = EditViewContainer({
  ...settings,
  meta: (props) => volumeFlavorAdd(props),
  title: 'Edit volume flavor',
});

export const VolumeFlavorCreateView = CreateViewContainer({
  ...settings,
  meta: (props) => volumeFlavorAdd(props),
  title: 'Create volume flavor',
});
