// (C) Copyright 2021-2024 Hewlett Packard Enterprise Development LP

import React, { useContext, useEffect, useState, useCallback } from 'react';
import {
  Box,
  FormField,
  Paragraph,
  Select,
  TextInput,
  ThemeContext,
  Button,
} from 'grommet';
import { useHistory } from 'react-router-dom';
import { Pause, Refresh, Resume, Search } from 'grommet-icons';

import NavTabs from '../../components/HybridNav/NavTabs';
import {
  Alerts,
  StyledDataTable,
  TableSettings,
  View,
  ViewHeader,
} from '../../components';
import { MSG_FETCHING, MSG_FETCH_NO_DATA } from '../../lib/messages';
import { isEmpty, useInterval } from '../../utils';
import { GetFilterMessage } from '../../utils/getFilterMessage';
import { AlertsContext } from '../../utils/context/alertsContext';
import {
  useVisibleColumns,
  useDataColumns,
  useHeaderActions,
  useDataSearch,
  useRowProps,
  useDataSort,
} from '../../utils/hooks';
import IconButton from '../../components/generic/IconButton';
import { REFRESH_TIME } from '../../config';
import { useMembershipContext } from '../../utils/context/membershipContext';
import auth from '../../lib/auth';
import styled from 'styled-components';
import { InlineNotification } from '../../components/generic/InlineNotification';

const RECORDS_TO_DISPLAY = 100;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StylesTitle = styled.div`
  flex: 1;
`;

export default function ListPageWithNav({
  navSection = '',
  actions = {},
  dataParam = {},
  dataProps = {},
  noClickRow,
  noSelect,
  parentId,
  path,
  sort: defaultSort,
  tableProps = {},
  title,
  useGetData,
  dynamicLevel,
  limitRecords = false,
}) {
  const theme = useContext(ThemeContext);
  const { addAlert, alerts } = useContext(AlertsContext);
  const { activeMembership, role } = useMembershipContext();
  const history = useHistory();
  const [interval, setInterval] = useState(null);
  const [newColumns, setNewColumns] = useState({});
  const [apiParams, setApiParams] = useState(
    limitRecords ? { limit: RECORDS_TO_DISPLAY + 1 } : {},
  );
  const [showAllRecords, setShowAllRecords] = useState(false);

  const { columns, data, isLoading, refetch } = useGetData({
    addAlert,
    parentId,
    param: dataParam,
    hosterId: activeMembership?.hoster_id,
    props: dataProps,
    scope: role?.scope,
    apiParams,
  });

  if (showAllRecords && data?.length > RECORDS_TO_DISPLAY) {
    actions.allowPause = false;
    actions.poll = false;
    if (interval) {
      clearInterval(interval);
      setInterval(null);
    }
  }

  const { visibleColumns, visibleColumnNames } = useVisibleColumns(
    path,
    newColumns,
    columns,
  );
  const { filteredData, search, setSearch } = useDataSearch(data);
  let limitedData = [];
  if (filteredData) {
    limitedData =
      showAllRecords || !limitRecords
        ? filteredData
        : filteredData.slice(0, RECORDS_TO_DISPLAY); // Show first RECORDS_TO_DISPLAY items if not showing all
  }
  const { dataColumns, resetSelected, selected } = useDataColumns(
    visibleColumns,
    limitedData,
    noSelect,
  );
  const base = actions.base ?? path;
  const {
    handleDelete,
    handleImport,
    handlePublish,
    handleUnpublish,
    handleUpdate,
  } = useHeaderActions({
    actions: {
      ...actions,
      base,
      title,
    },
    selected,
    refetch,
    resetSelected,
  });
  const [sort, setSort] = useState({
    ...defaultSort,
    external: true,
  });
  const { sortedData } = useDataSort(limitedData, sort);
  const rowProps = useRowProps(selected);

  useEffect(() => {
    if (actions.poll) {
      setInterval(REFRESH_TIME);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [actions, interval]);

  useInterval(() => {
    refetch(true);
  }, interval);

  const handlePause = () => {
    if (interval) {
      setInterval(null);
    } else {
      setInterval(REFRESH_TIME);
    }
  };

  const handleRefresh = () => {
    refetch();
  };

  const handleSearch = ({ target: { value } }) => {
    setSearch(value);
  };

  const handleSettingChange = (updatedColumns) => setNewColumns(updatedColumns);

  const handleClickRow = useCallback(
    !noClickRow
      ? ({ datum: { id } }) => history.push(`/s/${path}/${id}`)
      : undefined,
    [],
  );

  const handleFetchAll = () => {
    if (showAllRecords) {
      setApiParams({ limit: RECORDS_TO_DISPLAY + 1 });
      setShowAllRecords(false);
    } else {
      setApiParams({});
      setShowAllRecords(true);
    }
    refetch();
  };

  const handleSort = ({ property, direction }) => {
    setSort({ property, direction, external: true });
    refetch();
  };

  const skeleton = { message: { start: 'Loading', end: '' } };

  return (
    <View>
      <NavTabs navSection={navSection} />
      <ViewHeader
        createLabel={actions.allowCreate}
        onCreate={actions.allowCreateCb}
        onDelete={handleDelete}
        onImport={handleImport}
        onPublish={handlePublish}
        onUpdate={handleUpdate}
        onUnpublish={handleUnpublish}
        extraActions={actions.extra}
        level={dynamicLevel}
      >
        <TitleContainer>
          <StylesTitle>{title}</StylesTitle>
        </TitleContainer>
      </ViewHeader>

      <Alerts alerts={alerts} />

      <InlineNotification id={base} margin={{ bottom: 'small' }} />

      {!isEmpty(dataParam) ? (
        <Box pad={{ vertical: 'small' }} width='small'>
          <FormField label={dataParam.label}>
            <Select
              onChange={dataParam.onChange}
              options={dataParam.options}
              value={dataParam.value}
            />
          </FormField>
        </Box>
      ) : null}

      <Box pad={{ bottom: 'none' }} fill='horizontal'>
        <Box gap='xsmall' direction='row' justify='start'>
          <Box>
            <TextInput
              width='medium'
              type='search'
              placeholder='Search'
              icon={<Search />}
              value={search}
              onChange={handleSearch}
            />
          </Box>
          <Box alignSelf='end' direction='row' gap='xsmall'>
            {!isEmpty(visibleColumnNames) && (
              <TableSettings
                columnMetadata={columns}
                onChange={handleSettingChange}
                page={path}
                visibleColNames={visibleColumnNames}
              />
            )}
            {actions.allowPause ? (
              <IconButton
                icon={interval ? <Pause /> : <Resume />}
                kind='toolbar'
                onClick={handlePause}
                tip={interval ? 'Pause polling' : 'Resume polling'}
              />
            ) : (
              <IconButton
                icon={<Refresh />}
                kind='toolbar'
                onClick={handleRefresh}
                tip='Refresh'
              />
            )}
          </Box>
        </Box>
      </Box>

      {limitRecords &&
        data?.length === RECORDS_TO_DISPLAY + 1 &&
        !showAllRecords && (
          <Box pad='small' background='status-warning' align='center'>
            <Paragraph>
              Only the first {RECORDS_TO_DISPLAY} items are shown.
            </Paragraph>
          </Box>
        )}

      {(data?.length === RECORDS_TO_DISPLAY + 1 || showAllRecords) && (
        <Button
          label={
            showAllRecords
              ? `Show first ${RECORDS_TO_DISPLAY} items`
              : 'Show All'
          }
          onClick={handleFetchAll}
          margin={{ vertical: 'small' }}
        />
      )}

      <Box alignSelf='start' margin={{ top: '6px' }}>
        <GetFilterMessage data={limitedData} filteredData={limitedData} />
      </Box>
      <Box
        style={{ width: '100%', overflowX: 'auto' }}
        skeleton={isLoading ? skeleton : undefined}
      >
        <StyledDataTable
          columns={dataColumns}
          data={sortedData}
          onClickRow={handleClickRow}
          onSort={handleSort}
          primaryKey='id'
          rowProps={rowProps}
          sort={sort}
          sortable
          theme={theme}
          {...tableProps}
        />
      </Box>

      {!limitedData?.length ? (
        <Box
          pad={{ horizontal: 'medium', vertical: 'large' }}
          skeleton={isLoading ? skeleton : undefined}
        >
          <Paragraph>{isLoading ? MSG_FETCHING : MSG_FETCH_NO_DATA}</Paragraph>
        </Box>
      ) : null}
    </View>
  );
}
